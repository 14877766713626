/* eslint-disable react/jsx-props-no-spreading */

import React from "react"
import PropTypes from "prop-types"

import { AddressSearchGroupsText } from "../../constants"

function AddressAutocompleteOption({ renderOptionProps, currentAddress }) {
  return (
    <li {...renderOptionProps}>
      <div>
        {currentAddress.origin === AddressSearchGroupsText.AddressBook && (
          <div>
            <span style={{ fontWeight: "bold" }}> {currentAddress && currentAddress.companyName && currentAddress.companyName.concat(", ")}</span>
            <span style={{ fontWeight: "bold" }}> {currentAddress && currentAddress.personName && currentAddress.personName}</span>
          </div>
        )}
        <div>
          <span>{currentAddress && currentAddress.address && currentAddress.address.join(" ")}</span>
        </div>
      </div>
    </li>
  )
}

AddressAutocompleteOption.propTypes = {
  renderOptionProps: PropTypes.shape({
    "aria-disabled": PropTypes.bool,
    "aria-selected": PropTypes.bool,
    className: PropTypes.string,
    "data-option-index": PropTypes.number,
    id: PropTypes.string,
    key: PropTypes.string,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onTouchStart: PropTypes.func,
    role: PropTypes.string,
    tabIndex: PropTypes.number,
  }).isRequired,
  currentAddress: PropTypes.shape({
    address: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)), PropTypes.arrayOf(PropTypes.string)]),
    companyName: PropTypes.string,
    id: PropTypes.string,
    origin: PropTypes.string,
    personName: PropTypes.string,
  }).isRequired,
}

export default AddressAutocompleteOption
