// REACT
import React from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Formik
import { useFormik } from "formik"

// Custom
import CardContentNoPaddingBottom from "../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import GoogleAuth from "../google-auth"
import LanguageSelector from "../../../../soe-theme/src/components/language-selector"
import ModalAlert from "../../../modal/components/modal-alert"

import { LANGUAGES } from "../../../../utils/constants"
import { showSignIn, signUp } from "../../slice"

function SignUp() {
  const customTheme = useTheme()
  const { i18n, t } = useTranslation("authenticator")
  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.email) {
        errors.email = t("signUp.fields.email.error.required")
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
        errors.email = t("signUp.fields.email.error.invalid")
      }
      if (!formValues.password) {
        errors.password = t("signUp.fields.password.error.required")
      }
      if (!formValues.confirmPassword) {
        errors.confirmPassword = t("signUp.fields.confirmPassword.error.required")
      }
      if (formValues.password !== formValues.confirmPassword) {
        errors.confirmPassword = t("signUp.fields.confirmPassword.error.passwordMatch")
      }
      return errors
    },
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (formValues) => {
      dispatch(signUp(formValues))
    },
  })

  return (
    <Box className="signUp" component="div" my="auto" sx={{ justifyContent: "center" }}>
      <Grid container direction="column" alignItems="center">
        <Card mt={2} mb={2}>
          <CardHeader title={t("signUp.title")} />
          <Divider />
          <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(470) })}>
            <ModalAlert />
            <CardContentNoPaddingBottom sx={{ justifyContent: "center" }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <TextField
                  id="email"
                  inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular, lineHeight: customTheme.utils.pxToRem(24) } }}
                  label={t("signIn.fields.email.label")}
                  variant="outlined"
                  size="small"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && formik.errors.email !== undefined}
                  helperText={(formik.touched.email && formik.errors.email) || " "}
                  required
                  autoFocus
                />
                <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(16) })} />
                <Divider sx={{ position: "initial" }}>{t("signUp.separatorPassword")}</Divider>
                <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(8) })} />
                <TextField
                  id="password"
                  inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular, lineHeight: customTheme.utils.pxToRem(24) } }}
                  label={t("signUp.fields.password.label")}
                  variant="outlined"
                  size="small"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={formik.touched.password && formik.errors.password !== undefined}
                  helperText={(formik.touched.password && formik.errors.password) || " "}
                  fullWidth
                  required
                  type="password"
                />
                <CardContent sx={{ p: customTheme.utils.pxToRem(8) }} />
                <TextField
                  id="confirmPassword"
                  inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular, lineHeight: customTheme.utils.pxToRem(24) } }}
                  label={t("signUp.fields.confirmPassword.label")}
                  variant="outlined"
                  size="small"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  error={formik.touched.confirmPassword && formik.errors.confirmPassword !== undefined}
                  helperText={(formik.touched.confirmPassword && formik.errors.confirmPassword) || " "}
                  fullWidth
                  required
                  type="password"
                />
                <Box component="div">
                  <CardContent sx={{ display: "grid", justifyContent: "center" }}>
                    <Button type="submit" sx={{ display: "flex", textTransform: "none", borderRadius: "2px" }}>
                      {t("signUp.fields.signUpButton.label")}
                    </Button>
                  </CardContent>
                </Box>
                <CardContent sx={{ p: customTheme.utils.pxToRem(16) }} />
                <Divider sx={{ position: "initial" }}>{t("signUp.separator")}</Divider>
                <CardContent sx={{ p: customTheme.utils.pxToRem(8) }} />
                <Box component="div">
                  <CardContent sx={{ display: "grid", justifyContent: "center" }}>
                    <GoogleAuth label={t("signUp.googleSignUpButton.label")} />
                  </CardContent>
                </Box>
                <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(8) })} />
                <Box component="div">
                  <CardContent sx={{ display: "grid", justifyContent: "center" }}>
                    <Typography sx={{ fontSize: "1.063rem" }}>
                      {t("signUp.haveAccount")}
                      <Link ml={4} onClick={() => dispatch(showSignIn())} sx={(theme) => ({ textDecoration: "none", fontSize: theme.typography.bodyMedium600.fontSize })}>
                        {t("signUp.showSignIn")}
                      </Link>
                    </Typography>
                  </CardContent>
                </Box>
                <Box component="div" pt={customTheme.utils.pxToRem(66)}>
                  <CardContentNoPaddingBottom sx={{ display: "grid", justifyContent: "center" }}>
                    <LanguageSelector i18n={i18n} languages={LANGUAGES} />
                  </CardContentNoPaddingBottom>
                </Box>
              </form>
            </CardContentNoPaddingBottom>
          </Box>
        </Card>
      </Grid>
    </Box>
  )
}

export default SignUp
