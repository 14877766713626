// REACT
import PropTypes from "prop-types"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

// MUI
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"

// Others
import SoeCancelIcon from "../../soe-theme/src/icons/SoeCancelIcon"
import { clearDrawerAlert } from "./slice"

function DrawerAlert({ displayCloseIconButton }) {
  const { showAlert, severity, message } = useSelector((state) => state.drawer.alert)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(clearDrawerAlert())
  }

  return (
    <Box component="div">
      <Collapse in={showAlert}>
        {showAlert && (
          <Alert
            severity={severity}
            onClose={handleClose}
            action={
              displayCloseIconButton && (
                <IconButton onClick={handleClose}>
                  <SoeCancelIcon size="medium" />
                </IconButton>
              )
            }
            sx={{ mb: 1 }}
          >
            <Typography component="span" variant="bodyMedium400" sx={(theme) => ({ lineHeight: theme.utils.pxToLineHeight(14, 24) })}>
              {message}
            </Typography>
          </Alert>
        )}
      </Collapse>
    </Box>
  )
}

DrawerAlert.defaultProps = {
  displayCloseIconButton: true,
}

DrawerAlert.propTypes = {
  displayCloseIconButton: PropTypes.bool,
}

export default DrawerAlert
