import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"

import { Route, Routes } from "react-router-dom"
import { useDispatch } from "react-redux"

import App from "./components/app"
import AddressBook from "./components/address-book"
import Home from "./components/home"
import NotFound from "./components/not-found"
import Quotations from "./components/quotations"
import RateRequestHistory from "./components/rate-request-history"

import Invoices from "./components/invoices"
import Shipments from "./components/shipments"
import Test from "./components/snippets"
import UserContext from "./services/user/context"
import UserProfile from "./components/user-profile"

import { isDev } from "./utils/get-environment"
import { ROUTES } from "./utils/constants"
import { setShowAuthenticator } from "./components/authenticator/slice"

function AppRoutes() {
  return (
    <Routes>
      {/* Main routes of the app */}
      <Route path={ROUTES.HOME} element={<App />}>
        <Route index element={<Home />} />
        <Route
          path={ROUTES.QUOTATIONS}
          element={
            <RequireAuth>
              <Quotations />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.ADDRESS_BOOK}
          element={
            <RequireAuth>
              <AddressBook />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.SHIPMENTS}
          element={
            <RequireAuth>
              <Shipments />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.INVOICES}
          element={
            <RequireAuth>
              <Invoices />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.RATE_REQUEST_HISTORY}
          element={
            <RequireAuth>
              <RateRequestHistory />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.USER_PROFILE}
          element={
            <RequireAuth>
              <UserProfile />
            </RequireAuth>
          }
        />
        {/* Routes that must exist but leads nowhere */}
        <Route path={ROUTES.GOOGLE_AUTH} element={<Home />} />
        {/* Temporary Route for development purpose */}
        {isDev() && <Route path={ROUTES.TEST} element={<Test />} />}
        {/* Manage all other routes */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

function RequireAuth({ children }) {
  const dispatch = useDispatch()

  const { currentUser } = useContext(UserContext)

  useEffect(() => {
    if (!currentUser) dispatch(setShowAuthenticator(true))
  }, [currentUser])

  return currentUser ? children : <div />
}

RequireAuth.propTypes = {
  children: PropTypes.node,
}

RequireAuth.defaultProps = {
  children: [],
}

export default AppRoutes
