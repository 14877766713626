import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import Currency from "../currency"
import DateTime from "../date-time"
import RateRequestSummaryCard from "../rate-request-summary-card"

import { isShippingOptionsComplete } from "../quotations/utils"
import { ShippingOptionsProps } from "../../models/rate-request"

function ShippingOptionsCard({ shippingOptions, setShowRateRequestDrawer, isEditable, ...sw }) {
  const { t } = useTranslation(["rateRequest", "quotations"])
  const theme = useTheme()

  const showRateRequestDrawer = () => {
    sw.stepWizard.goToNamedStep("shipping-options")
    setShowRateRequestDrawer(true)
  }

  const summaryStatusProps = { variant: isShippingOptionsComplete(shippingOptions) ? "success" : "warning" }

  return (
    <RateRequestSummaryCard title={t("shippingOptionsTitle", { ns: "quotations" })} handleClick={showRateRequestDrawer} summaryStatusProps={summaryStatusProps} showStatus={isEditable} isClickable={isEditable}>
      {shippingOptions && (
        <Box mt={1} sx={{ display: "flex", flexDirection: "column" }}>
          {shippingOptions.shippingDate && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {t("shippingOptionsCard.shippingDate", { ns: "quotations" })}
              <DateTime date={shippingOptions.shippingDate} timezone={shippingOptions?.shippingDateTimezone} />
            </Typography>
          )}
          {shippingOptions.insurance && shippingOptions.insurance?.amount && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {t("shippingOptionsCard.insurance", { ns: "quotations" })}
              <Currency amount={shippingOptions.insurance.amount} currency={shippingOptions.insurance.currency} currencyPosition="right" color={theme.palette.contentNeutralSecondary.main} showSymbol />
            </Typography>
          )}
          {shippingOptions.signature && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {t("shippingOptionsCard.signature.label", { ns: "quotations" })}
              {t(`shippingOptionsCard.signature.options.${shippingOptions.signature}`, { ns: "quotations" })}
            </Typography>
          )}
          {shippingOptions.nonConveyable && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {t("shippingOptionsCard.nonConveyable", { ns: "quotations" })}
            </Typography>
          )}
          {shippingOptions.createReturnLabel && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {t("shippingOptionsCard.createReturnLabel", { ns: "quotations" })}
            </Typography>
          )}
          {shippingOptions.deliveryInstructions && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {t("shippingOptionsCard.deliveryInstructions", { ns: "quotations" })}
            </Typography>
          )}
        </Box>
      )}
    </RateRequestSummaryCard>
  )
}

ShippingOptionsCard.propTypes = {
  shippingOptions: ShippingOptionsProps,
  setShowRateRequestDrawer: PropTypes.func,
  isEditable: PropTypes.bool,
}

ShippingOptionsCard.defaultProps = {
  shippingOptions: undefined,
  setShowRateRequestDrawer: () => {},
  isEditable: true,
}

export default ShippingOptionsCard
