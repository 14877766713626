// REACT
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import UserContext from "../../../../../../services/user/context"

// Others
import UserProfileSummaryCard from "../../../user-profile-summary-card"
import { SummaryStatusVariantsEnum } from "../../../user-profile-summary-card/components/summary-status"
import { LANGUAGES } from "../../../../../../utils/constants"

function GeneralInfoLanguageCard({ handleGeneralInfoLanguageCardClick }) {
  const { t } = useTranslation("userProfile")
  const customTheme = useTheme()
  const { currentUser } = useContext(UserContext)

  const getSummaryStatus = () => SummaryStatusVariantsEnum.success

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <UserProfileSummaryCard title={t("userProfile.generalInfoLanguageCard.title")} handleClick={handleGeneralInfoLanguageCardClick} summaryStatusProps={summaryStatusProps}>
      {currentUser?.defaultLanguage && (
        <Box
          mt={customTheme.spacing(1)}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {t("userProfile.generalInfoLanguageCard.fields.language.label")} {LANGUAGES.find((language) => language.code === currentUser?.defaultLanguage).name}
          </Typography>
        </Box>
      )}
    </UserProfileSummaryCard>
  )
}

GeneralInfoLanguageCard.propTypes = {
  handleGeneralInfoLanguageCardClick: PropTypes.func,
}

GeneralInfoLanguageCard.defaultProps = {
  handleGeneralInfoLanguageCardClick: () => {},
}

export default GeneralInfoLanguageCard
