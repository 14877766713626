// REACT
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import UserProfileSummaryCard from "../../../user-profile-summary-card"
import { SummaryStatusVariantsEnum } from "../../../user-profile-summary-card/components/summary-status"
import UserContext from "../../../../../../services/user/context"

function MonetaryBillingPrefInfoCard({ handleMonetaryInfoBillingPrefCardClick }) {
  const { t } = useTranslation("userProfile")
  const customTheme = useTheme()

  const { currentUser } = useContext(UserContext)

  const getSummaryStatus = () => SummaryStatusVariantsEnum.success
  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <UserProfileSummaryCard title={t("userProfile.monetaryBillingPrefInfoCard.title")} summaryStatusProps={summaryStatusProps} handleClick={handleMonetaryInfoBillingPrefCardClick}>
      {currentUser?.billing?.currency && (
        <Box
          mt={customTheme.spacing(1)}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {t("userProfile.monetaryBillingPrefInfoCard.fields.currency.label")} {currentUser?.billing?.currency}
          </Typography>
        </Box>
      )}
    </UserProfileSummaryCard>
  )
}

MonetaryBillingPrefInfoCard.propTypes = {
  handleMonetaryInfoBillingPrefCardClick: PropTypes.func,
}

MonetaryBillingPrefInfoCard.defaultProps = {
  handleMonetaryInfoBillingPrefCardClick: () => {},
}

export default MonetaryBillingPrefInfoCard
