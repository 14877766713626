import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import CarrierDetailsCard from "../../../../../carrier-details-card"
import PriceSummaryCard from "../../../../../price-summary-card"
import TermsAndConditions from "./components/terms-and-conditions"

import { SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"

function PaymentDetails({ setShowPaymentDrawer, ...wizardProps }) {
  const { t } = useTranslation("payment")

  const { quoteInformation } = useSelector((state) => state.quotations)

  const handleCloseClick = () => {
    setShowPaymentDrawer(false)
  }

  const handleContinueClick = () => {
    wizardProps.nextStep()
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerHeader title={t("drawer.confirmation.title.label")} setShowDrawer={setShowPaymentDrawer} />
      <SoeDrawerContent>
        <CarrierDetailsCard
          service={quoteInformation.service}
          carrier={quoteInformation.carrier}
          commitment={quoteInformation.commitment}
          charges={quoteInformation.rate.charges}
          isBestPrice={quoteInformation.isBestPrice}
          isBestChoice={quoteInformation.isBestChoice}
          isFastDelivery={quoteInformation.isFastDelivery}
        />
        <PriceSummaryCard rate={quoteInformation.rate} />
        <TermsAndConditions />
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={[
          {
            action: handleCloseClick,
            label: t("drawer.confirmation.actions.prevButton.label"),
            variant: "outlined",
          },
          {
            action: handleContinueClick,
            label: t("drawer.confirmation.actions.nextButton.label"),
            variant: "contained",
          },
        ]}
      />
    </SoeDrawerContainer>
  )
}

PaymentDetails.propTypes = {
  setShowPaymentDrawer: PropTypes.func.isRequired,
}
export default PaymentDetails
