// REACT
import PropTypes from "prop-types"
import React, { useState } from "react"

import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

// MUI
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

// Others
import AddressSummary from "../../../address-summary"
import AppConfig from "../../../../utils/app-config"
import CarrierServiceLogo from "../../../carrier-service-logo"
import Currency from "../../../currency"
import MoreMenu from "../../../more-menu"
import PrintLabelsDrawer from "./components/print-labels-drawer"
import OrderProps from "../../../../models/order"
import restApiSlice from "../../../../services/rest-api/slice"
import ShipmentDetailsDrawer from "../shipment-details-drawer"
import ShipmentsStatus from "./constants"
import SoeConfirmationDialog from "../../../../soe-theme/src/components/soe-confirmation-dialog"
import SoeConfirmationDialogContainer from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-container"
import SoeConfirmationDialogHeader from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-header"
import SoeConfirmationDialogContent from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-content"
import SoeConfirmationDialogActions from "../../../../soe-theme/src/components/soe-confirmation-dialog/components/soe-confirmation-dialog-actions"
import TranslationService from "../../../../services/translation"

import { IMPERIAL, METRIC } from "../../../../services/units-conversion"
import { TRANSACTION_STATUS_ENUM } from "../../../transaction-status"
import { useDeleteOrderMutation } from "../../../../services/order/slice"
import DateTime from "../../../date-time"

function Shipment({ shipment, showServiceName }) {
  const { i18n, t } = useTranslation("shipments")
  const dispatch = useDispatch()

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
  const [showShipmentDetailsDrawer, setShowShipmentDetailsDrawer] = useState(false)
  const [showPrintLabelsDrawer, setShowPrintLabelsDrawer] = useState(false)
  const [deleteOrder, { data: deleteOrderResponse, isLoading, isError }] = useDeleteOrderMutation()
  const [showTransactionStatus, setShowTransactionStatus] = useState(false)

  const handleShipmentClick = () => {
    setShowTransactionStatus(false)
    setShowShipmentDetailsDrawer(true)
  }

  const handleCancelShipmentClick = () => {
    setShowTransactionStatus(false)
    setShowShipmentDetailsDrawer(true)
  }

  const handlePrintLabels = () => {
    setShowPrintLabelsDrawer(true)
  }

  const handleConfirmationDialogConfirm = () => {
    deleteOrder(shipment.id)
    setShowTransactionStatus(true)
    setShowConfirmationDialog(false)
  }

  const sideMenuOptionList = [
    {
      label: t("aShipmentDetails.optionsList.details.label"),
      handleClick: handleShipmentClick,
    },
    {
      label: t("aShipmentDetails.optionsList.cancelShipment.label"),
      handleClick: handleCancelShipmentClick,
    },
    {
      label: t("aShipmentDetails.optionsList.printLabels.label"),
      handleClick: handlePrintLabels,
    },
  ]

  const handleConfirmationDialogClose = () => {
    setShowConfirmationDialog(false)
    setShowShipmentDetailsDrawer(false)
  }

  const handleClose = () => {
    if (showTransactionStatus && deleteOrderResponse?.status === TRANSACTION_STATUS_ENUM.SUCCESS) dispatch(restApiSlice.util.invalidateTags(["PendingShipments"]))
    setShowShipmentDetailsDrawer(false)
  }

  return (
    <>
      <TableRow hover onClick={handleShipmentClick} component="tr" sx={{ backgroundColor: "white" }}>
        <TableCell padding="none" align="center">
          {showServiceName ? (
            <b>{TranslationService.getLabelFromLocale(i18n.language, shipment.quotation?.response?.service?.shortName)}</b>
          ) : (
            <CarrierServiceLogo code={shipment.quotation.response.carrier.code} logo={shipment.quotation.response.carrier.logo} align="center" />
          )}
        </TableCell>
        <TableCell component="td" scope="row" align="center">
          {AppConfig.features.showOrderTypeColumn && <Chip label={t("aShipmentParts.parcel.label")} color="secondary" size="small" />}
        </TableCell>
        <TableCell component="td" scope="row" align="center">
          <Box>{shipment.number}</Box>
          <Box>{shipment.tracking?.masterId}</Box>
        </TableCell>
        <TableCell component="td" scope="row">
          <AddressSummary addressSummary={shipment.quotation?.request?.shipper} />
        </TableCell>
        <TableCell component="td" scope="row">
          <AddressSummary addressSummary={shipment.quotation?.request?.recipient} />
        </TableCell>
        <TableCell component="td" scope="row">
          {shipment.totalPackages}
        </TableCell>
        <TableCell component="td" scope="row">
          {shipment.totalWeight.toFixed(1)} {shipment?.quotation?.request?.packages?.unitOfMeasure === IMPERIAL && "lb"}
          {shipment?.quotation?.request?.packages?.unitOfMeasure === METRIC && "kg"}
        </TableCell>
        <TableCell component="td" scope="row">
          <Currency amount={shipment.quotation?.response?.rate?.totals?.discounted?.total?.amount} currency={shipment.quotation?.response?.rate?.totals?.discounted?.total?.currency} currencyPosition="right" showSymbol />
        </TableCell>
        <TableCell component="td" scope="row">
          <Typography variant="bodySmall400" gutterBottom>
            <DateTime date={shipment.quotation?.request?.shippingOptions.shippingDate} timezone={shipment.quotation?.request?.shippingOptions?.shippingDateTimezone} />
          </Typography>
        </TableCell>
        <TableCell component="td" scope="row">
          {shipment?.transitStatus === ShipmentsStatus.Pending && t("shipmentStatus.pending.label")}
          {shipment?.transitStatus === ShipmentsStatus.InTransit && t("shipmentStatus.inTransit.label")}
          {shipment?.transitStatus === ShipmentsStatus.Completed && t("shipmentStatus.completed.label")}
        </TableCell>
        <TableCell component="td" scope="row" />
        <TableCell component="td" scope="row" align="right">
          <MoreMenu optionsList={sideMenuOptionList} />
        </TableCell>
      </TableRow>
      {showShipmentDetailsDrawer && (
        <ShipmentDetailsDrawer
          selectedOrderId={shipment.id}
          showShipmentDetailsDrawer={showShipmentDetailsDrawer}
          setShowShipmentDetailsDrawer={setShowShipmentDetailsDrawer}
          showTransactionStatus={showTransactionStatus}
          deleteOrderResponse={deleteOrderResponse}
          isError={isError}
          isLoading={isLoading}
          actions={
            !(deleteOrderResponse?.status === TRANSACTION_STATUS_ENUM.SUCCESS)
              ? [
                  {
                    action: () => setShowConfirmationDialog(true),
                    label: t("shipmentDetailsDrawer.actions.cancel"),
                    variant: "outlined",
                    color: "error",
                    loading: isLoading,
                  },
                  {
                    action: handleClose,
                    label: t("shipmentDetailsDrawer.actions.close"),
                    variant: "contained",
                    disabled: isLoading,
                  },
                ]
              : [
                  {
                    action: handleClose,
                    label: t("shipmentDetailsDrawer.actions.close"),
                    variant: "contained",
                    disabled: isLoading,
                  },
                ]
          }
        />
      )}
      {showPrintLabelsDrawer && <PrintLabelsDrawer selectedShipment={shipment} setShowPrintLabelsDrawer={setShowPrintLabelsDrawer} showPrintLabelsDrawer={showPrintLabelsDrawer} />}
      {showConfirmationDialog && (
        <SoeConfirmationDialog showConfirmationDialog={showConfirmationDialog} setShowConfirmationDialog={setShowConfirmationDialog}>
          <SoeConfirmationDialogContainer>
            <SoeConfirmationDialogHeader
              title={t("shipmentDetailsDrawer.confirmationDialog.title")}
              setShowConfirmationDialog={() => {
                setShowConfirmationDialog(false)
              }}
            />
            <SoeConfirmationDialogContent>
              <Typography variant="bodyMedium600" component="span" sx={{ textTransform: "none" }}>
                {t("shipmentDetailsDrawer.confirmationDialog.content.label")}
              </Typography>
            </SoeConfirmationDialogContent>
            <SoeConfirmationDialogActions
              buttons={[
                {
                  action: handleConfirmationDialogClose,
                  label: t("shipmentDetailsDrawer.confirmationDialog.actions.cancel.label"),
                  variant: "outlined",
                },
                {
                  action: handleConfirmationDialogConfirm,
                  label: t("shipmentDetailsDrawer.confirmationDialog.actions.confirm.label"),
                  variant: "contained",
                },
              ]}
            />
          </SoeConfirmationDialogContainer>
        </SoeConfirmationDialog>
      )}
    </>
  )
}

Shipment.propTypes = {
  shipment: OrderProps.isRequired,
  showServiceName: PropTypes.bool,
}

Shipment.defaultProps = {
  showServiceName: false,
}

export default Shipment
