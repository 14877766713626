// import Backend from "i18next-http-backend"
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import { initReactI18next } from "react-i18next"

import enAccountConfiguration from "./assets/locales/en/account-configuration.json"
import enAddressesTranslations from "./assets/locales/en/address-book.json"
import enAppBarTranslations from "./assets/locales/en/app-bar.json"
import enAuthenticatorTranslations from "./assets/locales/en/authenticator.json"
import enCarrierDetailsTranslations from "./assets/locales/en/carrier-details-card.json"
import enConfirmModalTranslations from "./assets/locales/en/confirmModal.json"
import enConstantTranslations from "./assets/locales/en/constant.json"
import enCountryTranslations from "./assets/locales/en/country.json"
import enDashboardTranslations from "./assets/locales/en/dashboard.json"
import enDataGridTranslations from "./assets/locales/en/data-grid.json"
import enDateRangeFilterTranslations from "./assets/locales/en/date-range-filter.json"
import enFreightOptionsCardTranslation from "./assets/locales/en/freight-options-card.json"
import enFullAddressFormTranslations from "./assets/locales/en/full-address-form.json"
import enHomeTranslations from "./assets/locales/en/home.json"
import enInvoicesTranslations from "./assets/locales/en/invoices.json"
import enPaymentTranslations from "./assets/locales/en/payment.json"
import enTermsAndConditions from "./assets/locales/en/terms-and-conditions.json"
import enTrackingNumberCardTranslations from "./assets/locales/en/tracking-number-card.json"
import enOrderNumberCardTranslations from "./assets/locales/en/order-number-card.json"
import enPriceSummaryCardTranslations from "./assets/locales/en/price-summary-card.json"
import enPrintLabels from "./assets/locales/en/print-labels.json"
import enProvinceTranslations from "./assets/locales/en/province.json"
import enQuotationsTranslations from "./assets/locales/en/quotations.json"
import enRateRequestTranslations from "./assets/locales/en/rate-request.json"
import enRateRequestHistoryTranslations from "./assets/locales/en/rate-request-history.json"
import enShipmentsTranslations from "./assets/locales/en/shipments.json"
import enSearchInputTranslations from "./assets/locales/en/search-input.json"
import enUserProfileTranslations from "./assets/locales/en/user-profile.json"
import enTimezoneDatetimeSelector from "./assets/locales/en/timezone-datetime-selector.json"
import enCustomPagination from "./assets/locales/en/custom-pagination.json"

import frAccountConfiguration from "./assets/locales/fr/account-configuration.json"
import frAddressesTranslations from "./assets/locales/fr/address-book.json"
import frAppBarTranslations from "./assets/locales/fr/app-bar.json"
import frAuthenticatorTranslations from "./assets/locales/fr/authenticator.json"
import frCarrierDetailsTranslations from "./assets/locales/fr/carrier-details-card.json"
import frConfirmModalTranslations from "./assets/locales/fr/confirmModal.json"
import frConstantTranslations from "./assets/locales/fr/constant.json"
import frCountryTranslations from "./assets/locales/fr/country.json"
import frDashboardTranslations from "./assets/locales/fr/dashboard.json"
import frDataGridTranslations from "./assets/locales/fr/data-grid.json"
import frDateRangeFilterTranslations from "./assets/locales/fr/date-range-filter.json"
import frFreightOptionsCardTranslation from "./assets/locales/fr/freight-options-card.json"
import frFullAddressFormTranslations from "./assets/locales/fr/full-address-form.json"
import frHomeTranslations from "./assets/locales/fr/home.json"
import frInvoicesTranslations from "./assets/locales/fr/invoices.json"
import frPaymentTranslations from "./assets/locales/fr/payment.json"
import frTermsAndConditions from "./assets/locales/fr/terms-and-conditions.json"
import frTrackingNumberCardTranslations from "./assets/locales/fr/tracking-number-card.json"
import frOrderNumberCardTranslations from "./assets/locales/fr/order-number-card.json"
import frPriceSummaryCardTranslations from "./assets/locales/fr/price-summary-card.json"
import frPrintLabels from "./assets/locales/fr/print-labels.json"
import frProvinceTranslations from "./assets/locales/fr/province.json"
import frQuotationsTranslations from "./assets/locales/fr/quotations.json"
import frRateRequestTranslations from "./assets/locales/fr/rate-request.json"
import frRateRequestHistoryTranslations from "./assets/locales/fr/rate-request-history.json"
import frShipmentsTranslations from "./assets/locales/fr/shipments.json"
import frSearchInputTranslations from "./assets/locales/fr/search-input.json"
import frUserProfileTranslations from "./assets/locales/fr/user-profile.json"
import frTimezoneDatetimeSelector from "./assets/locales/fr/timezone-datetime-selector.json"
import frCustomPagination from "./assets/locales/fr/custom-pagination.json"

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    load: "languageOnly",
    ns: [
      "accountConfiguration",
      "addressBook",
      "appBar",
      "authenticator",
      "carrierDetails",
      "confirmModal",
      "constant",
      "country",
      "customPagination",
      "dashbord",
      "data-grid",
      "dateRangeFilter",
      "fullAddressForm",
      "home",
      "invoices",
      "payment",
      "priceSummaryCard",
      "printLabels",
      "province",
      "quotations",
      "userProfile",
      "rateRequest",
      "rateRequestHistory",
      "shipments",
      "termsAndConditions",
      "timezoneDatetimeSelector",
      "trackingNumberCard",
    ],
    resources: {
      en: {
        accountConfiguration: enAccountConfiguration,
        addressBook: enAddressesTranslations,
        appBar: enAppBarTranslations,
        authenticator: enAuthenticatorTranslations,
        carrierDetails: enCarrierDetailsTranslations,
        confirmModal: enConfirmModalTranslations,
        constant: enConstantTranslations,
        country: enCountryTranslations,
        dashboard: enDashboardTranslations,
        dataGrid: enDataGridTranslations,
        dateRangeFilter: enDateRangeFilterTranslations,
        freightOptionsCard: enFreightOptionsCardTranslation,
        fullAddressForm: enFullAddressFormTranslations,
        home: enHomeTranslations,
        invoices: enInvoicesTranslations,
        orderNumberCard: enOrderNumberCardTranslations,
        payment: enPaymentTranslations,
        priceSummaryCard: enPriceSummaryCardTranslations,
        printLabels: enPrintLabels,
        province: enProvinceTranslations,
        quotations: enQuotationsTranslations,
        userProfile: enUserProfileTranslations,
        rateRequest: enRateRequestTranslations,
        rateRequestHistory: enRateRequestHistoryTranslations,
        shipments: enShipmentsTranslations,
        termsAndConditions: enTermsAndConditions,
        trackingNumberCard: enTrackingNumberCardTranslations,
        searchInput: enSearchInputTranslations,
        timezoneDatetimeSelector: enTimezoneDatetimeSelector,
        customPagination: enCustomPagination,
      },
      fr: {
        accountConfiguration: frAccountConfiguration,
        addressBook: frAddressesTranslations,
        appBar: frAppBarTranslations,
        authenticator: frAuthenticatorTranslations,
        carrierDetails: frCarrierDetailsTranslations,
        confirmModal: frConfirmModalTranslations,
        constant: frConstantTranslations,
        country: frCountryTranslations,
        dashboard: frDashboardTranslations,
        dataGrid: frDataGridTranslations,
        dateRangeFilter: frDateRangeFilterTranslations,
        freightOptionsCard: frFreightOptionsCardTranslation,
        fullAddressForm: frFullAddressFormTranslations,
        home: frHomeTranslations,
        invoices: frInvoicesTranslations,
        orderNumberCard: frOrderNumberCardTranslations,
        payment: frPaymentTranslations,
        priceSummaryCard: frPriceSummaryCardTranslations,
        printLabels: frPrintLabels,
        province: frProvinceTranslations,
        quotations: frQuotationsTranslations,
        userProfile: frUserProfileTranslations,
        rateRequest: frRateRequestTranslations,
        rateRequestHistory: frRateRequestHistoryTranslations,
        shipments: frShipmentsTranslations,
        termsAndConditions: frTermsAndConditions,
        trackingNumberCard: frTrackingNumberCardTranslations,
        searchInput: frSearchInputTranslations,
        timezoneDatetimeSelector: frTimezoneDatetimeSelector,
        customPagination: frCustomPagination,
      },
    },
    detection: {
      order: ["sessionStorage"],
      caches: ["sessionStorage"],
    },
  })

export default i18n
