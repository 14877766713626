import PropTypes from "prop-types"

// REACT
import React from "react"

import { useDispatch } from "react-redux"

// MUI
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"

// Theme
import { pxToRem } from "../../../../soe-theme/src/utils"

// Custom
import AppConfig from "../../../../utils/app-config"
import GoogleSvg from "../../../../assets/images/google-logo.svg"

import { setLastLocation } from "../../slice"

function GoogleAuth({ label }) {
  const dispatch = useDispatch()

  const theme = useTheme()

  const handleGoogleAuth = () => {
    dispatch(setLastLocation(window.location.pathname))
    window.location.href = `${AppConfig.aws.cognito.hostedUiUrl}/oauth2/authorize?identity_provider=Google&redirect_uri=${window.location.origin}/googleauth&response_type=code&client_id=${AppConfig.aws.cognito.clientId}&scope=openid email profile aws.cognito.signin.user.admin`
  }

  return (
    <Box className="googleAuth" component="div">
      <Button
        variant="outlined"
        startIcon={<img src={GoogleSvg} alt="Google Logo" />}
        onClick={() => handleGoogleAuth()}
        sx={{
          background: theme.palette.contentNeutralPrimaryReverse.main,
          border: "1px solid",
          borderColor: theme.palette.strokeDefault.main,
          boxSizing: "border-box",
          borderRadius: "4px",
          padding: theme.spacing(1, 2),
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: theme.palette.contentNeutralPrimary.main,
            fontSize: pxToRem(18),
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: pxToRem(24),
          }}
        >
          {label}
        </Typography>
      </Button>
    </Box>
  )
}

GoogleAuth.propTypes = {
  label: PropTypes.string.isRequired,
}

export default GoogleAuth
