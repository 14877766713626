// REACT
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import UserProfileSummaryCard from "../../../user-profile-summary-card"
import { SummaryStatusVariantsEnum } from "../../../user-profile-summary-card/components/summary-status"
import UserContext from "../../../../../../services/user/context"

function NewPaymentMethodCard({ handleNewPaymentMethodCardClick }) {
  const { t } = useTranslation("userProfile")
  const customTheme = useTheme()

  const { currentUser } = useContext(UserContext)

  const getSummaryStatus = () => SummaryStatusVariantsEnum.add
  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <UserProfileSummaryCard title={t("userProfile.newPaymentMethodCard.title")} summaryStatusProps={summaryStatusProps} handleClick={handleNewPaymentMethodCardClick}>
      {currentUser && (
        <Box
          mt={customTheme.spacing(1)}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {t("userProfile.newPaymentMethodCard.fields.addNewCard.label")}
          </Typography>
        </Box>
      )}
    </UserProfileSummaryCard>
  )
}

NewPaymentMethodCard.propTypes = {
  handleNewPaymentMethodCardClick: PropTypes.func,
}

NewPaymentMethodCard.defaultProps = {
  handleNewPaymentMethodCardClick: () => {},
}

export default NewPaymentMethodCard
