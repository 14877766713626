import Card from "@mui/material/Card"
import { useTheme } from "@mui/material/styles"
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { setSortParams } from "../../slice"
import SortButton from "../sort-button"
import "./style.scss"

function QuoteSort() {
  const { t } = useTranslation("quotations")
  const theme = useTheme()

  const { sortParams } = useSelector((state) => state.quotations)
  const dispatch = useDispatch()

  const sort = (key, value) => {
    dispatch(setSortParams({ [key]: value }))
  }
  return (
    <Card
      square
      sx={{
        display: "flex",
        backgroundColor: theme.palette.contentNeutralPrimaryReverse.main,
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        height: theme.utils.pxToRem(40),
      }}
    >
      <SortButton
        title={t("sort.price")}
        value={sortParams.price}
        changeSort={() => sort("price", !sortParams.price)}
        sx={{
          flexGrow: 1,
          justifyContent: "flex-start",
        }}
      />
      <SortButton title={t("sort.date")} value={sortParams.date} changeSort={() => sort("date", !sortParams.date)} />
      <SortButton title={t("sort.value")} value={sortParams.value} changeSort={() => sort("value", !sortParams.value)} />
    </Card>
  )
}

export default QuoteSort
