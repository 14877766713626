import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"

export const MuiWrapper = styled(Box, { name: "welcome", slot: "Root" })(() => ({}))

export const CustomButton = styled(Button, { label: "CustomButton", slot: "Root" })(({ theme }) => ({
  "&.MuiButton-outlined": {
    backgroundColor: theme.palette.contentNeutralPrimaryReverse.main,
  },
}))
