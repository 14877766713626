// REACT
import React from "react"

// Mui
import Stack from "@mui/material/Stack"
import Paper from "@mui/material/Paper"

// others
import carrierLogos from "../../utils/public-carrier-logos"
import CarrierServiceLogo from "../carrier-service-logo"

import Welcome from "./components/welcome"
import Main from "../main"
import Footer from "../footer"

function Home() {
  return (
    <Main id="home" sx={{ margin: 0, display: "flex", flexDirection: "column" }}>
      <Welcome />
      <Footer>
        <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" flexWrap="wrap">
          {carrierLogos &&
            carrierLogos.map((carrier, index) => (
              <Paper key={`${index.toString()}`}>
                <CarrierServiceLogo code={carrier.code} logo={carrier.logo} align="center" />
              </Paper>
            ))}
        </Stack>
      </Footer>
    </Main>
  )
}

export default Home
