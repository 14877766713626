import React from "react"

import { useSelector } from "react-redux"

import ChangePassword from "./components/change-password"
import ConfirmSignUp from "./components/confirm-sign-up"
import ForgotPassword from "./components/forgot-password"
import ForgotPasswordSubmit from "./components/forgot-password-submit"
import SignIn from "./components/sign-in"
import SignUp from "./components/sign-up"

import "./style.scss"

function Authenticator() {
  const { formState } = useSelector((state) => state.authenticator)

  if (formState === "signIn") {
    return <SignIn />
  }
  if (formState === "signUp") {
    return <SignUp />
  }
  if (formState === "confirmSignUp") {
    return <ConfirmSignUp />
  }
  if (formState === "forgotPassword") {
    return <ForgotPassword />
  }
  if (formState === "forgotPasswordSubmit") {
    return <ForgotPasswordSubmit />
  }
  if (formState === "changePassword") {
    return <ChangePassword />
  }
  if (formState === "signedIn") {
    return <div />
  }
}

export default Authenticator
