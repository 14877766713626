// REACT
import React from "react"
import PropTypes from "prop-types"

// Custom style
import MuiWrapper from "./style"

function Footer({ children, ...muiBoxProps }) {
  return (
    <MuiWrapper component="footer" {...muiBoxProps}>
      {children}
    </MuiWrapper>
  )
}

Footer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Footer
