import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"

import UserContext from "../../../../../../services/user/context"
import FullAddressForm from "../../../../../full-address-form"
import { getAddressFormat, isRequiredField } from "../../../../../../services/address-form"
import { AddressTypeEnum } from "../../../../constants"
import { SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import { updateBroker } from "../../../../slice"
import { useAddAddressBookItemMutation, useEditAddressBookItemMutation } from "../../../../../address-book/slice"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../../../services/address-form/slice"

function RateRequestBroker({ setShowRateRequestDrawer, showRateRequestDrawer, ...wizardProps }) {
  const { t } = useTranslation("fullAddressForm")
  const dispatch = useDispatch()
  const { broker, internationalOptions } = useSelector((state) => state.quotations)
  const { currentUser } = useContext(UserContext)

  const [action, setAction] = useState("")
  const [saveDefaultAddressChecked, setSaveDefaultAddressChecked] = useState(false)
  const [saveDefaultAddressDisabled, setSaveDefaultAddressDisabled] = useState(false)

  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()

  const [addAddressBookItem, { data: newAddressBookItem }] = useAddAddressBookItemMutation()
  const [editAddressBookItem, { data: updatedAddressBookItem }] = useEditAddressBookItemMutation()

  useEffect(() => {
    if (newAddressBookItem && newAddressBookItem.data) {
      dispatch(updateBroker(newAddressBookItem.data))
    }

    if (updatedAddressBookItem && updatedAddressBookItem.data) {
      dispatch(updateBroker(updatedAddressBookItem.data))
    }
  }, [newAddressBookItem, updatedAddressBookItem])

  const formik = useFormik({
    initialValues: {
      id: broker?.id || undefined,
      companyName: broker?.companyName || "",
      personName: broker?.personName || "",
      addressLine1: broker?.addressLine1 || "",
      addressLine2: broker?.addressLine2 || "",
      city: broker?.city || "",
      provinceName: broker?.provinceName || "",
      provinceCode: broker?.provinceCode || "",
      countryCode: broker?.countryCode || currentUser.clientAddress.countryCode || "CA",
      postalCode: broker?.postalCode || "",
      residential: broker?.residential || undefined,
      phoneNumber: broker?.phoneNumber || "",
      emailAddress: broker?.emailAddress || "",
      timezone: broker?.timezone || undefined,
      validationProviderId: broker?.validationProviderId || undefined,
    },
    validate: async (formValues) => {
      const errors = {}

      if (!formValues.companyName.trim()) {
        errors.companyName = t("fields.companyName.error.required")
      }

      if (!formValues.personName.trim()) {
        errors.personName = t("fields.personName.error.required")
      }

      const addressFormat = getAddressFormat({ addressFormats, countries, countryCode: formValues.countryCode })

      addressFormat?.forEach((fieldFormat) => {
        if (isRequiredField({ addressFormat, fieldName: fieldFormat.name }) && !formValues[fieldFormat.name]) {
          errors[fieldFormat.name] = t(`fields.${fieldFormat.name}.error.required`)
        }
      })

      if (!formValues.phoneNumber.trim()) {
        errors.phoneNumber = t("fields.phoneNumber.error.required")
      }

      if (!formValues.emailAddress.trim()) {
        errors.emailAddress = t("fields.emailAddress.error.required")
      }

      return errors
    },
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: (formValues) => {
      formValues = { ...formValues, type: AddressTypeEnum.Broker }
      if (saveDefaultAddressChecked) {
        formValues = { ...formValues, isDefaultBroker: true }
      }
      if (formValues.id) {
        editAddressBookItem({ id: formValues.id, payload: formValues })
      } else {
        addAddressBookItem({ ...formValues })
      }

      if (action === "continue") {
        wizardProps.nextStep()
      }
      if (action === "saveAndClose") setShowRateRequestDrawer(false)
    },
  })

  useEffect(() => {
    if (!showRateRequestDrawer) formik.resetForm()
  }, [showRateRequestDrawer])

  useEffect(() => {
    formik.setErrors({})
    formik.setTouched({})
  }, [formik.values.countryCode])

  const handleSaveAndCloseClick = () => {
    formik.handleSubmit()
    setAction("saveAndClose")
  }

  useEffect(() => {
    if (formik.values.id && formik.values.id === currentUser.defaultBrokerId) {
      setSaveDefaultAddressDisabled(true)
      setSaveDefaultAddressChecked(true)
    } else {
      setSaveDefaultAddressDisabled(false)
      setSaveDefaultAddressChecked(false)
    }
  }, [formik.values.id, currentUser.defaultBrokerId])

  const handleContinueClick = () => {
    formik.handleSubmit()
    setAction("continue")
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerHeader title={t("drawer.broker.title.label", { ns: "rateRequest" })} setShowDrawer={setShowRateRequestDrawer} />
      <SoeDrawerContent>
        <FullAddressForm
          formik={formik}
          displaySearchAddressBar
          displayEmailAddressField
          displaySaveAddressCheckbox={false}
          displaySaveDefaultAddressCheckbox
          displayResidentialToggle={false}
          saveAddressCheckboxLabel={formik.values.id ? t("broker.updateAddressCheckbox.label") : t("broker.saveAddressCheckbox.label")}
          saveDefaultAddressCheckboxLabel={t("broker.saveDefaultAddressCheckbox.label")}
          saveDefaultAddressChecked={saveDefaultAddressChecked}
          setSaveDefaultAddressChecked={setSaveDefaultAddressChecked}
          saveDefaultAddressDisabled={saveDefaultAddressDisabled}
          addressType={AddressTypeEnum.Broker}
        />
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={
          !internationalOptions?.documentsOnly
            ? [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.broker.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
                {
                  action: handleContinueClick,
                  label: t("drawer.broker.actions.nextButton.label", { ns: "rateRequest" }),
                  variant: "contained",
                },
              ]
            : [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.broker.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
              ]
        }
      />
    </SoeDrawerContainer>
  )
}

RateRequestBroker.propTypes = {
  setShowRateRequestDrawer: PropTypes.func.isRequired,
  showRateRequestDrawer: PropTypes.bool.isRequired,
}

RateRequestBroker.defaultProps = {}

export default RateRequestBroker
