import { setShowSpinner, updateRates } from "../../components/quotations/slice"
import { setPaymentStatus, setIsLoading } from "../../components/quotations/components/payment/slice"

import { MESSAGE_STATUS, MESSAGE_TYPE } from "./constant"

import AppConfig from "../../utils/app-config"
import restApiSlice from "../rest-api/slice"

import { getJwtTokens } from "../../components/authenticator/slice"
import { isDev } from "../../utils/get-environment"
import { createAlert } from "../../components/alert/slice"
import { setTransactionStatus } from "../../components/quotations/components/payment/components/payment-transaction/slice"

let socket
let timer
let user

const WebSocketService = {
  init(dispatch, currentUser) {
    user = currentUser
    if (!socket) socket = new WebSocket(AppConfig.websocket.url)
    if (!timer) timer = setInterval(this.sendPing, AppConfig.websocket.keepaliveDelay)

    socket.onopen = () => {}

    socket.onmessage = (e) => {
      const { event, data } = JSON.parse(e.data)
      switch (event) {
        case MESSAGE_TYPE.CONNECTION:
          if (currentUser.websocket.previousConnectionId && isDev()) dispatch(createAlert("Websocket reconnected at ".concat(new Date().toISOString())))
          currentUser.websocket.connectionId = data.connectionId
          this.sendIdentity(currentUser.websocket.previousConnectionId)
          break
        case MESSAGE_TYPE.RATE_RESPONSE:
          dispatch(updateRates(data.event.data))
          break
        case MESSAGE_TYPE.RATE_STATUS:
          if (data.event.status === MESSAGE_STATUS.RECEIVED || data.event.status === MESSAGE_STATUS.STARTED) {
            dispatch(restApiSlice.util.invalidateTags(["RateRequestHistory"]))
            dispatch(setShowSpinner(true))
          } else if (data.event.status === MESSAGE_STATUS.SUCCEEDED) {
            dispatch(setShowSpinner(false))
          }
          break
        case MESSAGE_TYPE.PAYMENT_STATUS:
          // eslint-disable-next-line no-console
          if (isDev()) console.log("MESSAGE_TYPE.PAYMENT_STATUS DATA : ", data)
          dispatch(setPaymentStatus(data))
          dispatch(setIsLoading(false))
          break
        case MESSAGE_TYPE.CREATE_ORDER_STATUS:
          // eslint-disable-next-line no-console
          if (isDev()) console.log("CREATE_ORDER_STATUS DATA : ", data)
          dispatch(setTransactionStatus(data))
          dispatch(restApiSlice.util.invalidateTags(["UserProfile"]))
          break
        default:
          break
      }
    }

    socket.onclose = () => {
      if (socket) socket = undefined
      if (timer) {
        clearInterval(timer)
        timer = undefined
      }
      if (user) {
        if (isDev()) dispatch(createAlert("Oh no! Websocket's closed at ".concat(new Date().toISOString())))
        currentUser.websocket.previousConnectionId = currentUser.websocket.connectionId
        currentUser.websocket.connectionId = undefined
        this.init(dispatch, currentUser)
      }
    }

    socket.onerror = () => {
      this.close()
    }
  },

  async sendIdentity(previousConnectionId) {
    const response = await getJwtTokens()
    socket.send(
      JSON.stringify({
        action: "message",
        identity: response.idToken,
        previousConnectionId,
      })
    )
  },

  sendPing() {
    socket.send(JSON.stringify({ action: "ping" }))
  },

  close() {
    user = undefined
    socket.close()
  },
}

export default WebSocketService
