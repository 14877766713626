import frLocale from "date-fns/locale/fr"
import enLocale from "date-fns/locale/en-US"

export const LOCALES = {
  fr: frLocale,
  en: enLocale,
}

export const LANGUAGES = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "fr",
    name: "Français",
  },
]

export const ROUTES = {
  HOME: "/",
  QUOTATIONS: "/quotations",
  RATE_REQUEST_HISTORY: "/quotations-history",
  SHIPMENTS: "/shipments",
  INVOICES: "/invoices",
  ADDRESS_BOOK: "address-book",
  USER_PROFILE: "/user-profile",
  GOOGLE_AUTH: "/googleauth",
  TEST: "/test",
}

export const FREIGHT_OPTIONS = {
  AFTER_HOURS: [
    {
      label: "delivery",
      value: "delivery",
    },
    {
      label: "pickup",
      value: "pickup",
    },
    {
      label: "both",
      value: "both",
    },
  ],
  TWO_PERSONS: [
    {
      label: "delivery",
      value: "delivery",
    },
    {
      label: "pickup",
      value: "pickup",
    },
    {
      label: "both",
      value: "both",
    },
  ],
  TAILGATE: [
    {
      label: "delivery",
      value: "delivery",
    },
    {
      label: "pickup",
      value: "pickup",
    },
    {
      label: "both",
      value: "both",
    },
  ],
}

export const PAYMENT_WIZARD_STEPS_ENUM = {
  PAYMENT_DETAILS: "payment-details",
  PAYMENT_VALIDATION: "payment-validation",
  PAYMENT_METHOD: "payment-method",
  PAYMENT_TRANSACTION: "payment-transaction",
}

export const PAYMENT_MAX_CREDITCARDS = 3
