const billDutyToList = [
  { label: "sender", value: "sender" },
  { label: "recipient", value: "recipient" },
  { label: "thirdParty", value: "thirdParty" },
]

const businessRelationshipList = [
  { label: "related", value: "related" },
  { label: "unrelated", value: "unrelated" },
]

const importExportList = [
  { label: "permanent", value: "permanent" },
  { label: "return", value: "return" },
  { label: "repair", value: "repair" },
  { label: "returnRepair", value: "returnRepair" },
]

export { billDutyToList, businessRelationshipList, importExportList }
