// REACT
import { useTheme } from "@emotion/react"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Others
import UserProfileSummaryCard from "../../../user-profile-summary-card"
import { SummaryStatusVariantsEnum } from "../../../user-profile-summary-card/components/summary-status"
import UserContext from "../../../../../../services/user/context"

function GeneralInfoContactCard({ handleGeneralInfoContactCardClick }) {
  const { t } = useTranslation("userProfile")
  const { currentUser } = useContext(UserContext)
  const theme = useTheme()

  const getSummaryStatus = () => SummaryStatusVariantsEnum.success

  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <UserProfileSummaryCard title={t("userProfile.generalInfoContactCard.title.label")} handleClick={handleGeneralInfoContactCardClick} summaryStatusProps={summaryStatusProps}>
      {currentUser?.clientAddress && Object.keys(currentUser?.clientAddress).length > 0 && (
        <Box
          mt={1}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {currentUser?.clientAddress?.companyName && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {currentUser?.clientAddress?.companyName}
            </Typography>
          )}
          {currentUser?.clientAddress?.personName && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {currentUser?.clientAddress?.personName}
            </Typography>
          )}
          {currentUser?.clientAddress?.addressLine1 && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {currentUser?.clientAddress?.addressLine1}
            </Typography>
          )}
          {currentUser?.clientAddress?.addressLine2 && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {currentUser?.clientAddress?.addressLine2}
            </Typography>
          )}
          {(currentUser?.clientAddress?.city || currentUser?.clientAddress?.provinceCode) && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {`${currentUser?.clientAddress?.city || ""}${currentUser?.clientAddress?.city && currentUser?.clientAddress?.provinceCode ? ", " : ""}${currentUser?.clientAddress?.provinceCode || ""}${
                (currentUser?.clientAddress?.city || currentUser?.clientAddress?.provinceCode) && currentUser?.clientAddress?.countryCode ? ", " : ""
              }${currentUser?.clientAddress?.countryCode || ""}`}
            </Typography>
          )}
          {currentUser?.clientAddress?.postalCode && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {currentUser?.clientAddress?.postalCode}
            </Typography>
          )}
          {currentUser?.clientAddress?.phoneNumber && (
            <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
              {currentUser?.clientAddress?.phoneNumber}
            </Typography>
          )}
        </Box>
      )}
    </UserProfileSummaryCard>
  )
}

GeneralInfoContactCard.propTypes = {
  handleGeneralInfoContactCardClick: PropTypes.func,
}

GeneralInfoContactCard.defaultProps = {
  handleGeneralInfoContactCardClick: () => {},
}

export default GeneralInfoContactCard
