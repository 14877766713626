// REACT
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Others
import UserProfileSummaryCard from "../../../user-profile-summary-card"
import UserContext from "../../../../../../services/user/context"
import TranslationService from "../../../../../../services/translation"

import { SummaryStatusVariantsEnum } from "../../../user-profile-summary-card/components/summary-status"
import { useGetCarriersQuery } from "../../../../slice"

function RegularlyScheduledPickupsCard({ handleRegularlyScheduledPickupsCardClick }) {
  const { i18n, t } = useTranslation("userProfile")

  const getSummaryStatus = () => SummaryStatusVariantsEnum.success
  const { data: carriers } = useGetCarriersQuery()
  const { currentUser } = useContext(UserContext)
  const summaryStatusProps = { variant: getSummaryStatus() }

  return (
    <UserProfileSummaryCard title={t("userProfile.regularlyScheduledPickupsCard.title")} handleClick={handleRegularlyScheduledPickupsCardClick} summaryStatusProps={summaryStatusProps} isDisabled={false}>
      <Box mt={1} sx={{ display: "flex", flexDirection: "column" }}>
        {currentUser?.regularPickups?.length > 0 ? (
          <Box component="div">
            {currentUser.regularPickups.map((regularPickup, index) => {
              return (
                <Box component="div" key={`${index.toString()}`}>
                  <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
                    {TranslationService.getLabelFromLocale(i18n.language, carriers?.find((carrier) => carrier.code === regularPickup)?.fullName)}
                  </Typography>
                </Box>
              )
            })}
          </Box>
        ) : (
          <Typography variant="bodySmall400" gutterBottom sx={(theme) => ({ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) })}>
            {t("userProfile.regularlyScheduledPickupsCard.fields.noRegularPickup.label")}
          </Typography>
        )}
      </Box>
    </UserProfileSummaryCard>
  )
}

RegularlyScheduledPickupsCard.propTypes = {
  handleRegularlyScheduledPickupsCardClick: PropTypes.func,
}

RegularlyScheduledPickupsCard.defaultProps = {
  handleRegularlyScheduledPickupsCardClick: () => {},
}

export default RegularlyScheduledPickupsCard
