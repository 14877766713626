import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import TextField from "@mui/material/TextField"

import { useTranslation } from "react-i18next"

import AutoCompletePaper from "../../soe-theme/src/components/auto-complete-paper"
import ClientAutocompleteOption from "./components/client-autocomplete-option"
import UserContext from "../../services/user/context"

import { useGetClientsQuery } from "../../services/clients/slice"

function ClientsAutocomplete({ formik }) {
  const { t } = useTranslation("appBar")

  const { currentUser } = useContext(UserContext)

  const { data: clients } = useGetClientsQuery()

  return (
    <Box component="div" sx={(theme) => ({ marginBottom: theme.spacing(1) })}>
      <Autocomplete
        value={clients?.find((element) => formik.values.client?.emailAddress === element.emailAddress) || null}
        size="small"
        disablePortal
        onChange={(_, value) => {
          formik.setFieldValue("client", value)
        }}
        isOptionEqualToValue={(option, value) => {
          return option.emailAddress === value.emailAddress
        }}
        name="client"
        onBlur={formik.handleBlur}
        getOptionLabel={(option) => {
          return option.emailAddress
        }}
        renderOption={(props, option) => {
          return <ClientAutocompleteOption key={"".concat(option.emailAddress)} renderOptionProps={props} client={option} />
        }}
        options={clients?.filter((client) => client.emailAddress !== currentUser.emailAddress) || []}
        PaperComponent={AutoCompletePaper}
        renderInput={(params) => <TextField {...params} label={t("impersonateClientSelectionDrawer.clientsAutocomplete.fields.client.label")} error={formik.touched.client && formik.errors.client !== undefined} />}
        noOptionsText={t("impersonateClientSelectionDrawer.clientsAutocomplete.fields.client.noClients")}
        label={t("impersonateClientSelectionDrawer.clientsAutocomplete.fields.client.label")}
        filterOptions={(options, state) => {
          return options.filter(
            (option) =>
              option.emailAddress?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
              option.clientAddress?.personName?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
              option.clientAddress?.companyName?.toLowerCase().includes(state.inputValue.toLowerCase())
          )
        }}
      />
    </Box>
  )
}

ClientsAutocomplete.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
}

export default ClientsAutocomplete
