import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  showAlert: false,
  severity: undefined,
  message: undefined,
}

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    createAlert: (state, action) => {
      state.showAlert = true
      state.severity = "warning"
      state.message = action.payload
    },
    clearAlert: () => initialState,
  },
  extraReducers: {
    "restApi/error/global": (state, action) => {
      if (action.payload) {
        state.showAlert = true
        state.severity = "error"
        state.message = action.payload.data ? action.payload.data.message : action.payload.error
      }
    },
  },
})

export const { clearAlert, createAlert } = alertSlice.actions

export default alertSlice.reducer
