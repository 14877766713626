import Box from "@mui/material/Box"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo } from "react"

import { useDispatch, useSelector } from "react-redux"

import Quote from "../quote"
import SkeletonQuote from "../quote/skeleton"
import UserContext from "../../../../services/user/context"

import { clearRates, updateBroker, updateBuyer, updateCommercialInvoice, updateInternationalOptions, updateShipper, useGetRatesQuery, updateRateRequestId } from "../../slice"
import {
  isCommercialInvoiceComplete,
  isBrokerComplete,
  isBuyerComplete,
  isFreightOptionsComplete,
  isInternationalOptionsComplete,
  isPackagesComplete,
  isRecipientComplete,
  isShipperComplete,
  isShippingOptionsComplete,
  isFreightOptionsRequired,
  isInternationalShipment,
  isWeightsMatching,
} from "../../utils"
import { useGetAddressBookQuery } from "../../../address-book/slice"
import IncompleteRateRequest from "../incomplete-rate-request"
import NoRateResponse from "../no-rate-response"

function QuotationsList({ showRateRequestDrawer, setShowRateRequestDrawer, setShowPaymentDrawer }) {
  const dispatch = useDispatch()

  const { currentUser } = useContext(UserContext)

  const { rateRequestId, packages, rates, recipient, shipper, shippingOptions, internationalOptions, showSpinner, sortParams, freightOptions, commercialInvoice, broker, buyer } = useSelector((state) => state.quotations)

  const { data: addressBookAddresses } = useGetAddressBookQuery()

  useEffect(() => {
    if (!isInternationalShipment(shipper, recipient)) {
      dispatch(updateInternationalOptions(undefined))
      dispatch(updateCommercialInvoice(undefined))
      dispatch(updateBroker(undefined))
      dispatch(updateBuyer(undefined))
    }
  }, [isInternationalShipment(shipper, recipient)])

  useEffect(() => {
    if (addressBookAddresses && !shipper && currentUser && currentUser.defaultShipperId) {
      const userDefaultAddress = addressBookAddresses.find((addressBookAddress) => addressBookAddress.id === currentUser.defaultShipperId)
      if (userDefaultAddress) dispatch(updateShipper(userDefaultAddress))
    }
  }, [addressBookAddresses, shipper])

  const isRateRequestCompleted = () => {
    let completed = true
    if (!currentUser.websocket.connectionId) completed = false
    if (!isShipperComplete(shipper)) completed = false
    if (!isRecipientComplete(recipient)) completed = false
    if (!isPackagesComplete(packages) || !isWeightsMatching(packages, commercialInvoice)) completed = false
    if (!isShippingOptionsComplete(shippingOptions)) completed = false
    if (isFreightOptionsRequired(packages) && !isFreightOptionsComplete(freightOptions)) completed = false
    if (isInternationalShipment(shipper, recipient) && !isInternationalOptionsComplete(internationalOptions)) completed = false
    if (isInternationalShipment(shipper, recipient) && !internationalOptions?.documentsOnly && (!isCommercialInvoiceComplete(commercialInvoice) || !isWeightsMatching(packages, commercialInvoice))) completed = false
    if (isInternationalShipment(shipper, recipient) && internationalOptions?.brokerRequired && !isBrokerComplete(broker)) completed = false
    if (isInternationalShipment(shipper, recipient) && commercialInvoice?.buyerRequired && !isBuyerComplete(buyer)) completed = false
    return completed
  }

  const skipGetRates = () => {
    let skipRates = true
    if (!showRateRequestDrawer) {
      if (isRateRequestCompleted()) skipRates = false
    }
    return skipRates
  }

  const createGetRatesPayload = () => {
    return useMemo(
      () => ({
        rateRequestId,
        shipper,
        recipient,
        packages,
        shippingOptions,
        freightOptions,
        internationalOptions,
        broker,
        commercialInvoice,
        buyer,
      }),
      [shipper, recipient, packages, shippingOptions, freightOptions, internationalOptions, broker, commercialInvoice, buyer]
    )
  }

  const { data: ratesResponse, isFetching, isLoading } = useGetRatesQuery({ payload: createGetRatesPayload(), connectionId: currentUser.websocket.connectionId }, { skip: skipGetRates() })

  useEffect(() => {
    if (ratesResponse?.data?.requestId) dispatch(updateRateRequestId(ratesResponse?.data?.requestId))
  }, [ratesResponse])

  useEffect(() => {
    if (isFetching) {
      dispatch(clearRates())
    }
  }, [isFetching, dispatch])

  const sortQuotations = () => {
    const sortedQuotations = rates
      .map((q, index) => ({
        ...q,
        index,
        retailTotal: q.rate.totals.retail.total.amount,
        discountedTotal: q.rate.totals.discounted.total.amount,
        carrier: q.carrier,
      }))
      .map((q) => ({ ...q, value: (q.retailTotal - q.discountedTotal) / q.retailTotal }))
      .sort((a, b) => {
        if (sortParams && sortParams.date !== undefined) {
          if (!a.commitment.commitDate) return 1 * (sortParams.date ? 1 : -1)
          if (!b.commitment.commitDate) return -1 * (sortParams.date ? 1 : -1)
          return (new Date(`${a.commitment.commitDate} ${a.commitment.commitTime}`) - new Date(`${b.commitment.commitDate} ${b.commitment.commitTime}`)) * (sortParams.date ? 1 : -1)
        }
        if (sortParams && sortParams.price !== undefined) return (a.discountedTotal - b.discountedTotal) * (sortParams.price ? 1 : -1)
        if (sortParams && sortParams.value !== undefined) return (a.value - b.value) * (sortParams.value ? 1 : -1)
        return 0
      })

    const auxSortedQuotations = [...sortedQuotations]

    const fastDelivery = auxSortedQuotations.sort((a, b) => {
      if (!(a.commitment.commitDate && a.commitment.commitTime)) return 1
      if (!(b.commitment.commitDate && b.commitment.commitTime)) return -1
      return new Date(`${a.commitment.commitDate} ${a.commitment.commitTime}`) - new Date(`${b.commitment.commitDate} ${b.commitment.commitTime}`)
    })[0]

    const maxQuote = sortedQuotations.reduce((max, quote) => (max.value > quote.value ? max : quote), 0)
    const bestPrice = sortedQuotations.reduce((min, quote) => (min.discountedTotal < quote.discountedTotal ? min : quote), 0)

    if (sortedQuotations[sortedQuotations.findIndex((quote) => quote.index === maxQuote.index)]) sortedQuotations[sortedQuotations.findIndex((quote) => quote.index === maxQuote.index)].isBestChoice = true
    if (sortedQuotations[sortedQuotations.findIndex((quote) => quote.index === fastDelivery.index)]) sortedQuotations[sortedQuotations.findIndex((quote) => quote.index === fastDelivery.index)].isFastDelivery = true
    if (sortedQuotations[sortedQuotations.findIndex((quote) => quote.index === bestPrice.index)]) sortedQuotations[sortedQuotations.findIndex((quote) => quote.index === bestPrice.index)].isBestPrice = true

    return sortedQuotations
  }

  return (
    <Box component="div">
      {(!isRateRequestCompleted() || skipGetRates()) && !isFetching && !isLoading && !showSpinner && rates.length === 0 && <IncompleteRateRequest setShowRateRequestDrawer={setShowRateRequestDrawer} />}
      {!skipGetRates() && !isFetching && !showSpinner && isRateRequestCompleted() && rates.length === 0 && <NoRateResponse />}
      {(isFetching || showSpinner) && <SkeletonQuote />}
      {rates && sortQuotations().map((quote) => <Quote key={quote.index} quote={quote} setShowPaymentDrawer={setShowPaymentDrawer} />)}
      {(isFetching || showSpinner) && <SkeletonQuote />}
    </Box>
  )
}

QuotationsList.propTypes = {
  showRateRequestDrawer: PropTypes.bool.isRequired,
  setShowRateRequestDrawer: PropTypes.func,
  setShowPaymentDrawer: PropTypes.func,
}

QuotationsList.defaultProps = {
  setShowRateRequestDrawer: () => {},
  setShowPaymentDrawer: () => {},
}

export default QuotationsList
