// REACT
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Formik
import { useFormik } from "formik"

// Custom
import CardContentNoPaddingBottom from "../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import CardContentNoPaddingTop from "../../../../soe-theme/src/components/cardContent-no-padding-top"
import LanguageSelector from "../../../../soe-theme/src/components/language-selector"
import ModalAlert from "../../../modal/components/modal-alert"

import { confirmSignUp, forgotPassword, showSignIn } from "../../slice"
import { LANGUAGES } from "../../../../utils/constants"

function ConfirmSignUp() {
  const customTheme = useTheme()
  const { i18n, t } = useTranslation("authenticator")
  const dispatch = useDispatch()

  const [showSuccessAndSignInLink, setShowSuccessAndSignInLink] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: useSelector((state) => state.authenticator.email),
      verificationCode: "",
    },
    validate: (formValues) => {
      const errors = {}
      if (!formValues.verificationCode) {
        errors.verificationCode = t("confirmSignUp.fields.verificationCode.error.required")
      }
      return errors
    },
    validateOnChange: false,
    onSubmit: async (formValues) => {
      dispatch(confirmSignUp(formValues)).then((response) => {
        if (!response.error) setShowSuccessAndSignInLink(true)
      })
    },
  })

  return (
    <Box className="confirmSignUp" component="div" my="auto" sx={{ justifyContent: "center" }}>
      <Grid container direction="column" alignItems="center">
        <Card my={2}>
          <CardHeader title={t("confirmSignUp.title")} />
          <Divider />
          <Box component="div" mb={2} mt={1} mx={2} sx={(theme) => ({ width: theme.utils.pxToRem(470) })}>
            <ModalAlert />
            <CardContentNoPaddingBottom sx={{ justifyContent: "center" }}>
              <Box component="div">
                <CardContentNoPaddingTop sx={{ display: "grid", justifyContent: "center" }}>
                  <Typography sx={(theme) => ({ display: "flex", justifyContent: "center", fontSize: "1.063rem", fontWeight: theme.typography.fontWeightBold })}>{t("confirmSignUp.emailSent1")}</Typography>
                  <Typography sx={{ fontSize: "1.063rem" }}>{t("confirmSignUp.emailSent2")}</Typography>
                  <Typography sx={(theme) => ({ display: "flex", justifyContent: "center", fontSize: theme.typography.bodyMedium600.fontSize, textAlignLast: "center" })}> {formik.values.email}</Typography>
                </CardContentNoPaddingTop>
              </Box>
              <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(8) })} />
              <form onSubmit={formik.handleSubmit} noValidate>
                <TextField
                  id="verificationCode"
                  inputProps={{ style: { fontSize: customTheme.utils.pxToRem(16), fontWeight: customTheme.typography.fontWeightRegular, lineHeight: customTheme.utils.pxToRem(24) } }}
                  label={t("confirmSignUp.fields.verificationCode.label")}
                  variant="outlined"
                  size="small"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.verificationCode}
                  error={formik.touched.verificationCode && formik.errors.verificationCode !== undefined}
                  helperText={(formik.touched.verificationCode && formik.errors.verificationCode) || " "}
                  required
                  autoFocus
                  disabled={showSuccessAndSignInLink}
                />
                <CardContent sx={(theme) => ({ p: theme.utils.pxToRem(8) })} />
                {!showSuccessAndSignInLink ? (
                  <>
                    <Box component="div">
                      <CardContent sx={{ display: "grid", justifyContent: "center" }}>
                        <Button type="submit" sx={{ display: "flex", textTransform: "none", borderRadius: "2px" }}>
                          {t("confirmSignUp.fields.confirmSignUpButton.label")}
                        </Button>
                      </CardContent>
                    </Box>
                    <Box component="div">
                      <CardContentNoPaddingBottom sx={{ display: "grid", justifyContent: "center" }}>
                        <Link onClick={() => dispatch(forgotPassword(formik.values))} sx={(theme) => ({ textDecoration: "none", fontSize: theme.typography.bodyMedium600.fontSize })}>
                          {t("confirmSignUp.lostCode")}
                        </Link>
                      </CardContentNoPaddingBottom>
                    </Box>
                    <Box component="div">
                      <CardContent sx={{ display: "grid", justifyContent: "center" }}>
                        <Link onClick={() => dispatch(showSignIn())} sx={(theme) => ({ textDecoration: "none", fontSize: theme.typography.bodyMedium600.fontSize })}>
                          {t("confirmSignUp.showSignIn")}
                        </Link>
                      </CardContent>
                    </Box>
                  </>
                ) : (
                  <Box component="div" pb={customTheme.spacing(14)}>
                    <CardContentNoPaddingTop sx={{ display: "grid", justifyContent: "center" }}>
                      <Typography sx={(theme) => ({ display: "flex", justifyContent: "center", fontSize: "1.063rem", fontWeight: theme.typography.fontWeightBold })}>{t("confirmSignUp.emailAddressValidated")}</Typography>
                      <Typography sx={(theme) => ({ display: "flex", justifyContent: "center", fontSize: theme.typography.bodyMedium600.fontSize, textAlignLast: "center" })}>
                        {t("confirmSignUp.showSignInValidated1")}
                        <Link
                          ml={customTheme.utils.pxToRem(6)}
                          onClick={() => dispatch(showSignIn())}
                          sx={(theme) => ({ display: "flex", justifyContent: "center", textDecoration: "none", fontSize: theme.typography.bodyMedium600.fontSize, textAlignLast: "center" })}
                        >
                          {t("confirmSignUp.showSignInValidated2")}
                        </Link>
                      </Typography>
                    </CardContentNoPaddingTop>
                  </Box>
                )}
                <Box component="div" pt={customTheme.utils.pxToRem(284)}>
                  <CardContentNoPaddingBottom sx={{ display: "grid", justifyContent: "center" }}>
                    <LanguageSelector i18n={i18n} languages={LANGUAGES} />
                  </CardContentNoPaddingBottom>
                </Box>
              </form>
            </CardContentNoPaddingBottom>
          </Box>
        </Card>
      </Grid>
    </Box>
  )
}

export default ConfirmSignUp
