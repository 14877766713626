// React
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useDispatch } from "react-redux"

// MUI
import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"

// Stripe
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { setIsPending, setIsLoading, setPaymentStatus } from "../../../../../../slice"
import { handleStripeConfirmPayment, handleStripeSetupPayment } from "../../handler"

/**
 * To test:
 * Valid card   : 4111 1111 1111 1111
 * Invalid card : 4100 0000 0000 0019
 */
function PaymentElementForm({ paymentIntent }) {
  const dispatch = useDispatch()
  const elements = useElements()
  const stripe = useStripe()

  const [paymentElementReady, setPaymentElementReady] = useState(false)

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    // Stripe.js has not yet loaded.
    if (!stripe || !elements) {
      return
    }

    dispatch(setIsLoading(true))
    // @todo: clear paymentIntentStatus or error if we have one on submit
    let error

    if (paymentIntent.object === "setup_intent") {
      await handleStripeSetupPayment({ stripe, elements }).then(({ err }) => {
        error = err
      })
    } else {
      await handleStripeConfirmPayment({ stripe, elements }).then(({ err }) => {
        error = err
      })
    }
    if (error) {
      dispatch(setIsLoading(false))
      dispatch(
        setPaymentStatus({
          status: "error",
          messages: [
            {
              code: error.code,
              message: {
                en: error.message,
              },
              severity: "error",
            },
          ],
        })
      )
    }
  }

  /**
   * Triggered when the Element is fully rendered and can accept imperative `element.focus()` calls.
   * Called with a reference to the underlying [Element instance](https://stripe.com/docs/js/element).
   */
  const handleOnReady = () => {
    setPaymentElementReady(true)
    dispatch(setIsPending(false))
  }

  return (
    <form id="payment-element" onSubmit={handleSubmit}>
      {!paymentElementReady && <Skeleton variant="rectangular" width="100%" height={294} />}
      <Box sx={{ visibility: paymentElementReady ? "visible" : "hidden" }}>
        <PaymentElement onReady={handleOnReady} />
      </Box>
    </form>
  )
}

PaymentElementForm.propTypes = {
  paymentIntent: PropTypes.shape({
    object: PropTypes.string,
  }).isRequired,
}

export default PaymentElementForm
