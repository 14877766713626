// REACT
import React, { useState, useEffect, useContext } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Masonry from "@mui/lab/Masonry"
import Typography from "@mui/material/Typography"

// Custom theme
import { useTheme } from "@mui/material"

// Others
import PaymentMethodCard from "./components/payment-method-card"
import PaymentMethodDrawer from "./components/payment-method-drawer"
import NewPaymentMethodCard from "./components/new-payment-method-card"
import NewPaymentMethodDrawer from "./components/new-payment-method-drawer"
import UserContext from "../../../../services/user/context"

import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import { useEditUserProfileMutation } from "../../../../services/user/slice"
import { PAYMENT_MAX_CREDITCARDS } from "../../../../utils/constants"

function UserProfilePaymentMethodsInfo() {
  const { t } = useTranslation("userProfile")
  const { currentUser } = useContext(UserContext)

  const customTheme = useTheme()

  const [showPaymentMethodDrawer, setShowPaymentMethodDrawer] = useState(false)
  const [showNewPaymentMethodDrawer, setShowNewPaymentMethodDrawer] = useState(false)

  const [onSubmitEvent, setSubmitEvent] = useState()
  const [onDeleteEvent, setDeleteEvent] = useState()
  const [onUpdateEvent, setSetUpdateEvent] = useState()
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState()

  const [isLoadingPaymentMethod, setIsLoadingPaymentMethod] = useState(false)
  const [activePaymentMethods, setActivePaymentMethods] = useState()

  const [deletePaymentMethod, { isLoading: isLoadingDelete }] = useEditUserProfileMutation()
  const [updatePaymentMethod, { isLoading: isLoadingUpdate }] = useEditUserProfileMutation()

  const handlePaymentMethodCardClick = (paymentMethod) => {
    setDeleteEvent()
    setSetUpdateEvent()
    setCurrentPaymentMethod(paymentMethod)
    setShowPaymentMethodDrawer(true)
  }

  const handleNewPaymentMethodCardClick = () => {
    setSubmitEvent()
    setIsLoadingPaymentMethod(false)
    setShowNewPaymentMethodDrawer(true)
  }

  useEffect(() => {
    if (currentUser?.paymentMethods) {
      setActivePaymentMethods(currentUser.paymentMethods.filter((paymentMethod) => paymentMethod.status !== "deleted"))
    } else {
      setActivePaymentMethods([])
    }
  }, [currentUser])

  return (
    <Box component="div">
      <Box display="flex" pt={customTheme.utils.pxToThemeSpacing(20)} pr={customTheme.utils.pxToThemeSpacing(10)} pb={customTheme.utils.pxToThemeSpacing(20)} pl={customTheme.utils.pxToThemeSpacing(10)}>
        <Box component="h5">
          <Typography sx={(theme) => ({ fontSize: customTheme.utils.pxToRem(18), fontWeight: theme.typography.fontWeightBold })}>{t("userProfile.paymentMethods.title.label")}</Typography>
        </Box>
      </Box>
      <Box pl={customTheme.utils.pxToThemeSpacing(6)} sx={{ display: "flex" }}>
        <Masonry columns={4} sx={{ alignContent: "flex-start" }}>
          {activePaymentMethods?.map((paymentMethod) => (
            <PaymentMethodCard key={paymentMethod.id} paymentMethod={paymentMethod} handlePaymentMethodCardClick={() => handlePaymentMethodCardClick(paymentMethod)} />
          ))}
          {activePaymentMethods?.length < PAYMENT_MAX_CREDITCARDS && <NewPaymentMethodCard key="new" handleNewPaymentMethodCardClick={() => handleNewPaymentMethodCardClick()} />}
        </Masonry>
      </Box>
      <SoeDrawer showDrawer={showPaymentMethodDrawer} setShowDrawer={setShowPaymentMethodDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.paymentMethodCard.title")}
            setShowDrawer={() => {
              setShowPaymentMethodDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <PaymentMethodDrawer
              onDeleteEvent={onDeleteEvent}
              onUpdateEvent={onUpdateEvent}
              setShowDrawer={setShowPaymentMethodDrawer}
              paymentMethod={currentPaymentMethod}
              deletePaymentMethod={deletePaymentMethod}
              updatePaymentMethod={updatePaymentMethod}
            />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setDeleteEvent,
                label: t("userProfile.paymentMethodDrawer.actions.delete.label"),
                variant: "outlined",
                color: "error",
                disabled: currentPaymentMethod?.status === "default" || isLoadingUpdate,
                loading: isLoadingDelete,
              },
              {
                action: setSetUpdateEvent,
                label: t("userProfile.paymentMethodDrawer.actions.setAsDefault.label"),
                variant: "contained",
                disabled: currentPaymentMethod?.status === "default" || isLoadingDelete,
                loading: isLoadingUpdate,
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
      <SoeDrawer showDrawer={showNewPaymentMethodDrawer} setShowDrawer={setShowNewPaymentMethodDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.paymentMethodCard.title")}
            setShowDrawer={() => {
              setShowNewPaymentMethodDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <NewPaymentMethodDrawer onSubmitEvent={onSubmitEvent} setShowDrawer={setShowNewPaymentMethodDrawer} setIsLoadingPaymentMethod={setIsLoadingPaymentMethod} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: () => setShowNewPaymentMethodDrawer(false),
                label: t("userProfile.newPaymentMethodDrawer.actions.cancel.label"),
                variant: "outlined",
                disabled: isLoadingPaymentMethod,
              },
              {
                action: setSubmitEvent,
                label: t("userProfile.newPaymentMethodDrawer.actions.save.label"),
                variant: "contained",
                loading: isLoadingPaymentMethod,
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </Box>
  )
}

export default UserProfilePaymentMethodsInfo
