// REACT
import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import AutoCompletePaper from "../../../../../../../../soe-theme/src/components/auto-complete-paper"
import PACKAGING_TYPE from "../../constants"

import { LANGUAGES } from "../../../../../../../../utils/constants"
import { PackagingTypeProps } from "../../../../../../../../models/piece"
import { UnitsOfMeasureEnum } from "../../../../../../../../services/units-conversion"

// Custom style
import CustomTextField from "./style"

function PackageForm({ formik, index, packagingTypes }) {
  const { i18n, t } = useTranslation(["rateRequest"])
  const customTheme = useTheme()

  const isDimensionFixed = (name) => formik?.values?.pieces?.[index]?.packagingType?.dimensions?.[name]?.isFixed

  const SPACING = 12

  useEffect(() => {
    if (isDimensionFixed("length")) {
      formik.setFieldValue(
        `pieces[${index}].length`,
        formik.values?.pieces?.[index]?.packagingType?.dimensions?.length?.value?.[formik.values.unitOfMeasure] ? Number(formik.values.pieces[index].packagingType.dimensions.length.value[formik.values.unitOfMeasure]) : ""
      )
    }
    if (isDimensionFixed("width")) {
      formik.setFieldValue(
        `pieces[${index}].width`,
        formik.values?.pieces?.[index]?.packagingType?.dimensions?.width?.value?.[formik.values.unitOfMeasure] ? Number(formik.values.pieces[index].packagingType.dimensions.width.value[formik.values.unitOfMeasure]) : ""
      )
    }
    if (isDimensionFixed("height")) {
      formik.setFieldValue(
        `pieces[${index}].height`,
        formik.values?.pieces?.[index]?.packagingType?.dimensions?.height?.value?.[formik.values.unitOfMeasure] ? Number(formik.values.pieces[index].packagingType.dimensions.height.value[formik.values.unitOfMeasure]) : ""
      )
    }
  }, [formik.values.unitOfMeasure])

  const handleAdornmentBackgroundColor = (value) => {
    if (value !== undefined) return customTheme.palette.contentAlertError.main
    if (formik.values.unitOfMeasure === UnitsOfMeasureEnum.IMPERIAL) return customTheme.palette.contentActionDefault.main
    return customTheme.palette.contentAlertPositiveDarker.main
  }

  const getItemCountDescription = () => {
    if (i18n.resolvedLanguage === LANGUAGES[0].code) return `${t("drawer.packages.fields.itemCount.descriptionText")} ${formik.values?.pieces?.[index]?.packagingType?.name?.toLowerCase()}: `
    return `${t("drawer.packages.fields.itemCount.descriptionText")} "${formik.values?.pieces?.[index]?.packagingType?.name?.toLowerCase()}" : `
  }

  return (
    <Box component="div" sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
      <Box component="div" mb={customTheme.utils.pxToThemeSpacing(SPACING)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box component="div">
          <TextField
            name={`pieces[${index}].quantity`}
            id="quantity"
            label={t("drawer.packages.fields.quantity.label", { ns: "rateRequest" })}
            value={formik.values?.pieces?.[index]?.quantity ? Number(formik.values.pieces[index].quantity.toFixed(0)) : ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.pieces?.[index]?.quantity && formik.errors?.pieces?.[index]?.quantity}
            type="number"
            inputProps={{ min: 0 }}
            variant="outlined"
            size="small"
            sx={(theme) => ({
              width: theme.utils.pxToRem(82),
              mr: theme.utils.pxToRem(SPACING),
            })}
            required
          />
        </Box>
        <Autocomplete
          id="packagingTypes"
          options={packagingTypes || []}
          getOptionLabel={(option) => option.name}
          PaperComponent={AutoCompletePaper}
          renderInput={(params) => (
            <TextField
              {...params}
              name={`pieces[${index}].packagingTypes`}
              label={t("drawer.packages.fields.packagingType.label", { ns: "rateRequest" })}
              error={formik.touched?.pieces?.[index]?.packagingType && formik.errors?.pieces?.[index]?.packagingType}
              variant="outlined"
              required
            />
          )}
          isOptionEqualToValue={(option, value) => {
            return option.id === value.id
          }}
          onBlur={formik.handleBlur}
          onChange={(_, value) => {
            if (value) {
              formik.setFieldValue(`pieces[${index}].packagingType`, value)
              formik.setFieldValue(`pieces[${index}].length`, Number(value.dimensions.length.value[formik.values.unitOfMeasure]) || "")
              formik.setFieldValue(`pieces[${index}].width`, Number(value.dimensions.width.value[formik.values.unitOfMeasure]) || "")
              formik.setFieldValue(`pieces[${index}].height`, Number(value.dimensions.height.value[formik.values.unitOfMeasure]) || "")
            } else {
              formik.setFieldValue(`pieces[${index}].packagingType`, null)
              formik.setFieldValue(`pieces[${index}].length`, "")
              formik.setFieldValue(`pieces[${index}].width`, "")
              formik.setFieldValue(`pieces[${index}].height`, "")
            }
            formik.setFieldValue(`pieces[${index}].itemCount`, undefined)
          }}
          noOptionsText={t("drawer.packages.fields.packagingType.noOptionsText", { ns: "rateRequest" })}
          value={formik.values?.pieces?.[index]?.packagingType || null}
          size="small"
          sx={{ width: "100%" }}
        />
      </Box>
      {!formik?.values?.pieces?.[index]?.packagingType?.isEnvelope && (
        <>
          <Box component="div" mb={customTheme.utils.pxToThemeSpacing(SPACING)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <CustomTextField
              id="length"
              name={`pieces[${index}].length`}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    align="center"
                    position="end"
                    sx={{
                      backgroundColor: handleAdornmentBackgroundColor(formik.touched?.pieces?.[index]?.length && formik.errors?.pieces?.[index]?.length),
                    }}
                  >
                    {formik.values.unitOfMeasure === UnitsOfMeasureEnum.IMPERIAL ? (
                      <Typography variant="bodyMedium600" pl={i18n.resolvedLanguage === LANGUAGES[0].code ? customTheme.utils.pxToRem(6) : customTheme.utils.pxToRem(2)}>
                        {t("drawer.packages.dimensionMetrics.inch", { ns: "rateRequest" })}
                      </Typography>
                    ) : (
                      <Typography variant="bodyMedium600" pr={customTheme.utils.pxToRem(4)}>
                        {t("drawer.packages.dimensionMetrics.centimeter", { ns: "rateRequest" })}
                      </Typography>
                    )}
                  </InputAdornment>
                ),
                min: 0,
              }}
              value={formik.values?.pieces?.[index]?.length ? Number(formik.values.pieces[index].length) : ""}
              onChange={(event) => {
                if (event.target.value !== "") {
                  event.target.value = Number(event.target.value)
                }
                formik.handleChange(event)
              }}
              onBlur={formik.handleBlur}
              error={formik.touched?.pieces?.[index]?.length && formik.errors?.pieces?.[index]?.length}
              disabled={formik.values?.pieces?.[index]?.packagingType?.dimensions?.length?.isFixed}
              type="number"
              inputMode="numeric"
              label={t("drawer.packages.fields.length.label", { ns: "rateRequest" })}
              variant="outlined"
              size="small"
              required
            />
            <Typography
              mx={customTheme.utils.pxToThemeSpacing(2)}
              component="span"
              sx={(theme) => ({
                color: theme.palette.common.black,
                lineHeight: theme.utils.pxToRem(40),
                fontWeight: "bold",
              })}
            >
              /
            </Typography>
            <CustomTextField
              id="width"
              label={t("drawer.packages.fields.width.label", { ns: "rateRequest" })}
              name={`pieces[${index}].width`}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    align="center"
                    position="end"
                    sx={{
                      backgroundColor: handleAdornmentBackgroundColor(formik.touched?.pieces?.[index]?.width && formik.errors?.pieces?.[index]?.width),
                    }}
                  >
                    {formik.values.unitOfMeasure === UnitsOfMeasureEnum.IMPERIAL ? (
                      <Typography variant="bodyMedium600" pl={i18n.resolvedLanguage === LANGUAGES[0].code ? customTheme.utils.pxToThemeSpacing(6) : customTheme.utils.pxToThemeSpacing(2)}>
                        {t("drawer.packages.dimensionMetrics.inch", { ns: "rateRequest" })}
                      </Typography>
                    ) : (
                      <Typography variant="bodyMedium600" pr={customTheme.utils.pxToThemeSpacing(4)}>
                        {t("drawer.packages.dimensionMetrics.centimeter", { ns: "rateRequest" })}
                      </Typography>
                    )}
                  </InputAdornment>
                ),
                min: 0,
              }}
              value={formik.values?.pieces?.[index]?.width ? Number(formik.values.pieces[index].width) : ""}
              onChange={(event) => {
                if (event.target.value !== "") {
                  event.target.value = Number(event.target.value)
                }
                formik.handleChange(event)
              }}
              onBlur={formik.handleBlur}
              error={formik.touched?.pieces?.[index]?.width && formik.errors?.pieces?.[index]?.width}
              disabled={formik.values?.pieces?.[index]?.packagingType?.dimensions?.width?.isFixed}
              type="number"
              inputMode="numeric"
              variant="outlined"
              size="small"
              required
            />
            <Typography
              component="span"
              mx={customTheme.utils.pxToThemeSpacing(2)}
              sx={(theme) => ({
                color: theme.palette.common.black,
                lineHeight: theme.utils.pxToRem(40),
                fontWeight: "bold",
              })}
            >
              /
            </Typography>
            <CustomTextField
              id="height"
              name={`pieces[${index}].height`}
              InputProps={{
                endAdornment: (
                  <InputAdornment align="center" position="end" sx={{ backgroundColor: handleAdornmentBackgroundColor(formik.touched?.pieces?.[index]?.height && formik.errors?.pieces?.[index]?.height) }}>
                    {formik.values.unitOfMeasure === UnitsOfMeasureEnum.IMPERIAL ? (
                      <Typography variant="bodyMedium600" pl={i18n.resolvedLanguage === LANGUAGES[0].code ? customTheme.utils.pxToThemeSpacing(6) : customTheme.utils.pxToThemeSpacing(2)}>
                        {t("drawer.packages.dimensionMetrics.inch", { ns: "rateRequest" })}
                      </Typography>
                    ) : (
                      <Typography variant="bodyMedium600" pr={customTheme.utils.pxToThemeSpacing(4)}>
                        {t("drawer.packages.dimensionMetrics.centimeter", { ns: "rateRequest" })}
                      </Typography>
                    )}
                  </InputAdornment>
                ),
                min: 0,
              }}
              label={t("drawer.packages.fields.height.label", { ns: "rateRequest" })}
              value={formik.values?.pieces?.[index]?.height ? Number(formik.values.pieces[index].height) : ""}
              onChange={(event) => {
                if (event.target.value !== "") {
                  event.target.value = Number(event.target.value)
                }
                formik.handleChange(event)
              }}
              onBlur={formik.handleBlur}
              error={formik.touched?.pieces?.[index]?.height && formik.errors?.pieces?.[index]?.height}
              disabled={formik.values?.pieces?.[index]?.packagingType?.dimensions?.height?.isFixed}
              type="number"
              inputMode="numeric"
              variant="outlined"
              size="small"
              required
            />
          </Box>
          <Box component="div" mb={customTheme.utils.pxToThemeSpacing(SPACING)} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box component="div">
              <CustomTextField
                id="weight"
                label={t("drawer.packages.fields.weight.label", { ns: "rateRequest" })}
                name={`pieces[${index}].weight`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      align="center"
                      position="end"
                      sx={{
                        backgroundColor: handleAdornmentBackgroundColor(formik.touched?.pieces?.[index]?.weight && formik.errors?.pieces?.[index]?.weight),
                      }}
                    >
                      {formik.values.unitOfMeasure === UnitsOfMeasureEnum.IMPERIAL ? (
                        <Typography variant="bodyMedium600" pl={customTheme.utils.pxToThemeSpacing(4)}>
                          {t("drawer.packages.weightMetrics.pound", { ns: "rateRequest" })}
                        </Typography>
                      ) : (
                        <Typography variant="bodyMedium600" pl={customTheme.utils.pxToThemeSpacing(2)}>
                          {t("drawer.packages.weightMetrics.kilogram", { ns: "rateRequest" })}
                        </Typography>
                      )}
                    </InputAdornment>
                  ),
                  min: 0,
                }}
                value={formik.values?.pieces?.[index]?.weight ? Number(formik.values.pieces[index].weight) : ""}
                onChange={(event) => {
                  if (event.target.value !== "") {
                    event.target.value = Number(event.target.value)
                  }
                  formik.handleChange(event)
                }}
                onBlur={formik.handleBlur}
                error={formik.touched?.pieces?.[index]?.weight && formik.errors?.pieces?.[index]?.weight}
                type="number"
                variant="outlined"
                size="small"
                sx={(theme) => ({
                  width: theme.utils.pxToRem(110),
                  mr: theme.utils.pxToRem(12),
                })}
                required
              />
            </Box>
            {formik.values?.pieces[index]?.packagingType?.categoryName === PACKAGING_TYPE.FREIGHT && (
              <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="bodySmall400"
                  sx={(theme) => ({
                    mr: theme.utils.pxToRem(10),
                  })}
                >
                  {getItemCountDescription()}
                </Typography>
                <Box component="div">
                  <TextField
                    name={`pieces[${index}].itemCount`}
                    id="itemCount "
                    label={t("drawer.packages.fields.itemCount.label", { ns: "rateRequest" })}
                    value={formik.values?.pieces?.[index]?.itemCount ? Number(formik.values.pieces[index].itemCount.toFixed(0)) : ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched?.pieces?.[index]?.itemCount && formik.errors?.pieces?.[index]?.itemCount}
                    type="number"
                    inputProps={{ min: 0 }}
                    variant="outlined"
                    size="small"
                    sx={(theme) => ({
                      width: theme.utils.pxToRem(82),
                    })}
                    required
                  />
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

PackageForm.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number,
  deletePackageForm: PropTypes.bool,
  packagingTypes: PropTypes.arrayOf(PackagingTypeProps),
}

PackageForm.defaultProps = {
  index: undefined,
  deletePackageForm: false,
  packagingTypes: undefined,
}

export default PackageForm
