// REACT
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"

// Theme
import { useTheme } from "@mui/material/styles"

// Formik
import { useFormik } from "formik"

// Others
import AutoCompletePaper from "../../../../../../soe-theme/src/components/auto-complete-paper"
import TranslationService from "../../../../../../services/translation"
import UserContext from "../../../../../../services/user/context"

import { useEditUserProfileMutation } from "../../../../../../services/user/slice"
import { useGetCarriersQuery } from "../../../../slice"

function RegularlyScheduledPickupsDrawer({ onSubmitEvent, setRegularlyScheduledPickupsDrawer }) {
  const { i18n, t } = useTranslation("userProfile")
  const customTheme = useTheme()

  const [editUserProfile] = useEditUserProfileMutation()
  const { data: carriers } = useGetCarriersQuery()

  const { currentUser } = useContext(UserContext)

  const formik = useFormik({
    initialValues: {
      regularPickups: currentUser?.regularPickups?.map((regularPickup) => carriers.find((carrier) => carrier.code === regularPickup)) || [],
    },
    validate: () => {},
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      const regularPickups = formValues.regularPickups.map((regularPickup) => regularPickup.code)
      editUserProfile({
        regularPickups,
      })
      setRegularlyScheduledPickupsDrawer(false)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box component="div" my={customTheme.utils.pxToRem(10)}>
          <Autocomplete
            multiple
            id="regularPickups"
            name="regularPickups"
            options={carriers || []}
            getOptionLabel={(option) => {
              return TranslationService.getLabelFromLocale(i18n.language, option?.fullName)
            }}
            PaperComponent={AutoCompletePaper}
            renderInput={(params) => <TextField {...params} variant="outlined" label={t("userProfile.regularlyScheduledPickupsDrawer.fields.carriers.label")} />}
            isOptionEqualToValue={(option, value) => {
              return option.code === value.code
            }}
            onBlur={formik.handleBlur}
            onChange={(_, value) => {
              if (value) {
                formik.setFieldValue("regularPickups", value)
              } else {
                formik.setFieldValue("regularPickups", null)
              }
            }}
            noOptionsText={t("userProfile.regularlyScheduledPickupsDrawer.fields.noOptionsText.label")}
            value={formik.values.regularPickups || []}
            fullWidth
            size="small"
          />
        </Box>
      </form>
    </Box>
  )
}

RegularlyScheduledPickupsDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

RegularlyScheduledPickupsDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setRegularlyScheduledPickupsDrawer: PropTypes.func.isRequired,
}

export default RegularlyScheduledPickupsDrawer
