// REACT
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// MUI
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Skeleton from "@mui/material/Skeleton"
import Typography from "@mui/material/Typography"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import CreditCardList from "./components/credit-card-list"
import PriceSummaryCard from "../../../../../price-summary-card"
import Stripe from "./components/stripe"
import TransactionStatus, { TRANSACTION_STATUS_ENUM } from "../../../../../transaction-status"
import UserContext from "../../../../../../services/user/context"

import { paymentType } from "../../../../constants"
import { PAYMENT_MAX_CREDITCARDS } from "../../../../../../utils/constants"
import { SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import { useOnAccountPaymentMutation, useSavedPaymentMethodChargeMutation, useGetPaymentReadyQuery, setIsLoading, clearPayment } from "../../slice"

function PaymentMethod({ setShowPaymentDrawer, ...wizardProps }) {
  const { t } = useTranslation("payment")
  const customTheme = useTheme()

  const dispatch = useDispatch()

  const { currentUser } = useContext(UserContext)

  const { paymentStatus, isPending, isLoading } = useSelector((state) => state.payment)
  const { quoteInformation } = useSelector((state) => state.quotations)

  const [showTransactionStatus, setShowTransactionStatus] = useState(false)
  const [paymentCompleted, setPaymentCompleted] = useState(false)
  const [activePaymentMethods, setActivePaymentMethods] = useState()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState()

  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState()

  const { data: payment } = useGetPaymentReadyQuery({ rateResponseId: quoteInformation.rateResponseId, connectionId: currentUser.websocket.connectionId }, { skip: !currentUser || !quoteInformation?.rateResponseId })

  const [handleOnAccountPayment] = useOnAccountPaymentMutation()
  const [handleSavedCardPayment] = useSavedPaymentMethodChargeMutation()

  const handlePreviousStep = () => {
    wizardProps.previousStep()
  }

  const handleContinueButton = () => {
    wizardProps.nextStep()
  }

  const handlePayButton = () => {
    setShowTransactionStatus(true)
    dispatch(clearPayment())
    if (currentUser?.billing?.type === paymentType.creditcard && selectedPaymentMethod) {
      if (selectedPaymentMethod === "useNewCard") {
        document.getElementById("payment-element").dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }))
      } else {
        dispatch(setIsLoading(true))
        handleSavedCardPayment({ payload: { rateResponseId: quoteInformation.rateResponseId, paymentMethodId: selectedPaymentMethod }, connectionId: currentUser.websocket.connectionId })
      }
    }
    if (currentUser?.billing?.type === paymentType.account) {
      dispatch(setIsLoading(true))
      handleOnAccountPayment({ payload: { rateResponseId: quoteInformation.rateResponseId }, connectionId: currentUser.websocket.connectionId })
    }
  }

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value)
  }

  useEffect(() => {
    if (currentUser?.paymentMethods) {
      setActivePaymentMethods(currentUser.paymentMethods.filter((paymentMethod) => paymentMethod.status !== "deleted"))
      setDefaultPaymentMethod(currentUser.paymentMethods.find((paymentMethod) => paymentMethod.status === "default"))
    } else {
      setActivePaymentMethods([])
      setSelectedPaymentMethod("useNewCard")
    }
  }, [currentUser])

  useEffect(() => {
    if (paymentStatus?.status === TRANSACTION_STATUS_ENUM.SUCCESS) setPaymentCompleted(true)
  }, [paymentStatus])

  useEffect(() => {
    if (defaultPaymentMethod?.id) setSelectedPaymentMethod(defaultPaymentMethod?.id)
  }, [defaultPaymentMethod])

  return (
    <SoeDrawerContainer>
      <SoeDrawerHeader title={t("drawer.paymentMethod.title.label")} preventClosing={isLoading || paymentStatus?.status === TRANSACTION_STATUS_ENUM.SUCCESS} setShowDrawer={setShowPaymentDrawer} />
      <SoeDrawerContent drawerScrollToTop={showTransactionStatus}>
        {(showTransactionStatus || paymentCompleted) && <TransactionStatus transaction={paymentStatus} title={t("drawer.transactionConfirmation.processingStatus.payment.title")} isLoading={isLoading ?? isPending} />}
        <PriceSummaryCard rate={quoteInformation.rate} isFullFormat={false} />
        {payment?.type === paymentType.account && (
          <Box component="div" my={customTheme.spacing(1)}>
            <Alert icon={false} severity="info">
              <Typography variant="bodyMedium400">{t("drawer.paymentMethod.paymentOnAccount.label")}</Typography>
            </Alert>
          </Box>
        )}
        {payment?.type === paymentType.creditcard && activePaymentMethods && !paymentCompleted && (
          <FormControl sx={{ width: "100%" }}>
            <RadioGroup name="selectSavedPaymentMethod" onChange={handlePaymentMethodChange} defaultValue={defaultPaymentMethod?.id} value={selectedPaymentMethod?.id}>
              {currentUser.billing.type === paymentType.creditcard && activePaymentMethods?.length > 0 && <CreditCardList activePaymentMethods={activePaymentMethods} />}
              {activePaymentMethods?.length < PAYMENT_MAX_CREDITCARDS && (
                <Accordion defaultExpanded={false} expanded={selectedPaymentMethod === "useNewCard"} sx={{ width: "100%" }}>
                  <AccordionSummary expandIcon={<div />} id="accordionNewCard">
                    <FormControlLabel
                      checked={selectedPaymentMethod === "useNewCard"}
                      value="useNewCard"
                      control={<Radio />}
                      label="Enter a new credit card:"
                      sx={{ position: "absolute", paddingLeft: "6px", width: "100%", height: "100%", margin: "0", marginLeft: "-24px", marginTop: "-24px" }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    {selectedPaymentMethod === "useNewCard" && payment?.intent && <Stripe paymentIntent={payment?.intent} />}
                    {!payment && <Skeleton variant="rectangular" width="100%" height={294} />}
                  </AccordionDetails>
                </Accordion>
              )}
            </RadioGroup>
          </FormControl>
        )}
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={
          !paymentCompleted
            ? [
                {
                  action: handlePreviousStep,
                  label: t("drawer.paymentMethod.actions.prevButton.label"),
                  variant: "outlined",
                  disabled: isLoading,
                },
                {
                  action: handlePayButton,
                  label: t("drawer.paymentMethod.actions.payButton.label"),
                  variant: "contained",
                  disabled: !payment,
                  loading: isLoading,
                },
              ]
            : [
                {
                  action: handlePreviousStep,
                  label: t("drawer.paymentMethod.actions.prevButton.label"),
                  variant: "outlined",
                  disabled: isLoading,
                },
                {
                  action: handleContinueButton,
                  label: t("drawer.paymentMethod.actions.nextButton.label"),
                  variant: "contained",
                },
              ]
        }
      />
    </SoeDrawerContainer>
  )
}

PaymentMethod.propTypes = {
  setShowPaymentDrawer: PropTypes.func.isRequired,
}

export default PaymentMethod
