import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import CarrierServiceLogo from "../carrier-service-logo"
import DateTime from "../date-time"
import TranslationService from "../../services/translation"

import { CustomChip, MuiWrapper } from "./style"

function CarrierDetailsCard({ service, carrier, commitment, charges, isBestPrice, isBestChoice, isFastDelivery, ...muiBoxProps }) {
  const { t, i18n } = useTranslation(["carrierDetails", "quotations"])
  const theme = useTheme()

  return (
    <MuiWrapper {...muiBoxProps}>
      <Card variant="outlined">
        <CardContent
          sx={{
            "&:last-child": {
              pb: theme.spacing(2),
            },
          }}
        >
          <CarrierServiceLogo code={carrier.code} serviceName={service?.fullName} logo={carrier.logo} align="left" />
          <Box component="div">
            {commitment.commitDate || commitment.date ? (
              <DateTime date={commitment.commitDate || commitment.date} time={commitment.commitTime} timezoneCode={commitment.timezoneCode} timezone={commitment.timezone} />
            ) : (
              <Typography variant="inherit" component="span" sx={{ lineHeight: theme.utils.pxToRem(24) }}>
                {t("quote.noDeliveryDate", { ns: "quotations" })}
              </Typography>
            )}
          </Box>
          <Box component="div">
            {service?.chips?.length > 0 &&
              service.chips.map((chip, index) => (
                <CustomChip
                  key={`serviceChip${index}`}
                  sx={{
                    backgroundColor: theme.palette.contentAlertInformativeDarker.main,
                  }}
                  label={TranslationService.getLabelFromLocale(i18n.language, chip)}
                />
              ))}
            {charges &&
              charges.map((charge) => {
                return (
                  charge.chips &&
                  charge.chips.map((chip, index) => (
                    <CustomChip
                      key={`chargeChip${index}`}
                      sx={{
                        backgroundColor: "orange",
                      }}
                      label={TranslationService.getLabelFromLocale(i18n.language, chip)}
                    />
                  ))
                )
              })}
            {isBestPrice && <CustomChip label={t("quote.bestPrice", { ns: "quotations" })} />}
            {isBestChoice && <CustomChip label={t("quote.bestChoice", { ns: "quotations" })} />}
            {isFastDelivery && <CustomChip label={t("quote.fastDelivery", { ns: "quotations" })} />}
          </Box>
        </CardContent>
      </Card>
    </MuiWrapper>
  )
}

CarrierDetailsCard.propTypes = {
  service: PropTypes.shape({
    fullName: PropTypes.shape({}),
    chips: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  carrier: PropTypes.shape({
    logo: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
  commitment: PropTypes.shape({
    commitDate: PropTypes.string,
    date: PropTypes.string,
    commitTime: PropTypes.string,
    timezoneCode: PropTypes.string,
    timezone: PropTypes.string,
  }).isRequired,
  charges: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isBestPrice: PropTypes.bool,
  isBestChoice: PropTypes.bool,
  isFastDelivery: PropTypes.bool,
}

CarrierDetailsCard.defaultProps = {
  isBestPrice: false,
  isBestChoice: false,
  isFastDelivery: false,
}

export default CarrierDetailsCard
