// REACT
import React from "react"

import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import saveonexpress from "../../../../assets/images/saveonexpress.jpg"

// Custom style
import { CustomButton, MuiWrapper } from "./style"

// Others
import { ROUTES } from "../../../../utils/constants"

function Welcome({ ...muiBoxProps }) {
  const { t } = useTranslation("home")
  const navigate = useNavigate()

  const handleNavigateQuotation = () => {
    navigate(ROUTES.QUOTATIONS)
  }

  const handleClickShipNow = () => {
    handleNavigateQuotation()
  }

  return (
    <MuiWrapper component="section" {...muiBoxProps} sx={{ display: "flex", flexGrow: 1 }}>
      <Box
        pt={20}
        sx={{
          backgroundImage: `url(${saveonexpress})`,
          backgroundSize: "cover",
          backgroundPosition: "left center",
          backgroundRepeat: "no-repeat",
          flexGrow: 1,
        }}
      >
        <Box
          className="informationTextLabel"
          component="div"
          ml={4}
          pb={4}
          sx={(theme) => ({
            textTransform: "uppercase",
            width: theme.utils.pxToRem(682),
          })}
        >
          <Typography
            className="primaryLabel"
            component="h2"
            pb={2}
            sx={(theme) => ({
              color: theme.palette.contentNeutralPrimaryReverse.main,
              fontWeight: theme.typography.fontWeightBold,
              fontSize: theme.utils.pxToRem(44),
              lineHeight: theme.utils.pxToLineHeight(44, 48),
            })}
          >
            {t("homeInformation.primaryTitle.label")}
          </Typography>
          <Typography
            className="secondaryLabel"
            component="span"
            pb={2}
            sx={(theme) => ({
              color: theme.palette.contentNeutralPrimaryReverse.main,
              fontSize: theme.utils.pxToRem(20),
              lineHeight: theme.utils.pxToLineHeight(20, 24),
            })}
          >
            {t("homeInformation.secondaryTitle.label")}
          </Typography>
          <Box className="shipNowButton" component="div" pt={2}>
            <CustomButton onClick={() => handleClickShipNow()} variant="outlined" size="medium">
              <Typography variant="bodyMedium600" component="span">
                {t("shipNow.actions.label")}
              </Typography>
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </MuiWrapper>
  )
}

export default Welcome
