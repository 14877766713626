// REACT
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"

// Formik
import { useFormik } from "formik"

// Theme
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useTheme } from "@mui/material/styles"

// Others
import UserContext from "../../../../../../services/user/context"
import { LANGUAGES } from "../../../../../../utils/constants"

import { useEditUserProfileMutation } from "../../../../../../services/user/slice"

function GeneralInfoLanguageDrawer({ onSubmitEvent, setShowGeneralInfoLanguageDrawer }) {
  const { i18n, t } = useTranslation("userProfile")
  const customTheme = useTheme()

  const { currentUser } = useContext(UserContext)
  const [editUserProfile] = useEditUserProfileMutation()

  const formik = useFormik({
    initialValues: {
      defaultLanguage: currentUser.defaultLanguage || i18n.resolvedLanguage,
    },
    validate: () => {},
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      editUserProfile({
        defaultLanguage: formValues.defaultLanguage,
      })
      i18n.changeLanguage(formValues.defaultLanguage)
      setShowGeneralInfoLanguageDrawer(false)
    },
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
  }, [onSubmitEvent])

  return (
    <Box component="div">
      <form onSubmit={formik.handleSubmit} noValidate>
        <Box component="div" my={customTheme.utils.pxToRem(10)}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="defaultLanguageInputLabel">{t("userProfile.generalInfoLanguageDrawer.fields.language.label")}</InputLabel>
            <Select
              labelId="defaultLanguageLabel"
              id="defaultLanguage"
              name="defaultLanguage"
              label={t("userProfile.generalInfoLanguageDrawer.fields.language.label")}
              value={formik.values.defaultLanguage}
              onChange={formik.handleChange}
              SelectDisplayProps={{ style: { height: 0 } }}
            >
              {LANGUAGES.map((language, index) => {
                return (
                  <MenuItem key={`${index.toString()}`} value={language.code}>
                    {language.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Box>
      </form>
    </Box>
  )
}

GeneralInfoLanguageDrawer.defaultProps = {
  onSubmitEvent: undefined,
}

GeneralInfoLanguageDrawer.propTypes = {
  onSubmitEvent: PropTypes.instanceOf(Object),
  setShowGeneralInfoLanguageDrawer: PropTypes.func.isRequired,
}

export default GeneralInfoLanguageDrawer
