// REACT
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

// Others
import { createAction } from "@reduxjs/toolkit"
import OrderNumberCard from "../../../../../order-number-card"
import PrintLabels from "../../../../../print-labels"
import RateRequestSummaryCard from "../../../../../rate-request-summary-card"
import SoeDrawerActions from "../../../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-actions"
import SoeDrawerContainer from "../../../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-container"
import SoeDrawerContent from "../../../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-content"
import SoeDrawerHeader from "../../../../../../soe-theme/src/components/soe-drawer/components/soe-drawer-header"
import TrackingNumberCard from "../../../../../tracking-number-card"
import TransactionStatus, { TRANSACTION_STATUS_ENUM } from "../../../../../transaction-status"
import UserContext from "../../../../../../services/user/context"

import { ROUTES } from "../../../../../../utils/constants"
import restApiSlice from "../../../../../../services/rest-api/slice"
import { usePostOrderConfirmQuery } from "./slice"

function PaymentTransaction({ setShowPaymentDrawer }) {
  const { t } = useTranslation("payment")
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { currentUser } = useContext(UserContext)

  const { paymentStatus } = useSelector((state) => state.payment)
  const { transactionStatus } = useSelector((state) => state.transaction)
  const { quoteInformation } = useSelector((state) => state.quotations)

  const [isFinishedButtonDisabled, setIsFinishedButtonDisabled] = useState(true)
  const [postOrderConfirmPayload, setPostOrderConfirmPayload] = useState()

  useEffect(() => {
    if (currentUser && paymentStatus?.status === TRANSACTION_STATUS_ENUM.SUCCESS && quoteInformation?.rateResponseId) {
      setPostOrderConfirmPayload({
        rateResponseId: quoteInformation.rateResponseId,
        paymentIntent: paymentStatus,
      })
    }
  }, [paymentStatus])

  useEffect(() => {
    if (transactionStatus?.status !== TRANSACTION_STATUS_ENUM.PENDING) {
      setIsFinishedButtonDisabled(false)
    }
  }, [transactionStatus])

  const isSkipPostOrderConfirm = () => {
    if (quoteInformation?.rateResponseId && postOrderConfirmPayload?.connectionId && postOrderConfirmPayload?.paymentIntent?.status === TRANSACTION_STATUS_ENUM.SUCCESS) {
      return false
    }
    return true
  }

  usePostOrderConfirmQuery({ payload: postOrderConfirmPayload, connectionId: currentUser.websocket.connectionId }, { skip: isSkipPostOrderConfirm() || !currentUser })

  const handleCloseClick = () => {
    if (transactionStatus?.status === TRANSACTION_STATUS_ENUM.ERROR) {
      setShowPaymentDrawer(false)
      return
    }
    setShowPaymentDrawer(false)
    const resetQuotations = createAction("RESET_QUOTATIONS")
    dispatch(resetQuotations())
    dispatch(restApiSlice.util.invalidateTags(["PendingShipments"]))
    navigate(ROUTES.SHIPMENTS)
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerHeader title={t("drawer.transactionConfirmation.title.label")} setShowDrawer={setShowPaymentDrawer} preventClosing={paymentStatus?.status === TRANSACTION_STATUS_ENUM.SUCCESS} />
      <SoeDrawerContent>
        <TransactionStatus transaction={transactionStatus} title={t("drawer.transactionConfirmation.processingStatus.order.title")} isLoading={isFinishedButtonDisabled} />
        {transactionStatus?.metadata?.orderNumber && <OrderNumberCard orderNumber={transactionStatus.metadata.orderNumber} />}
        {transactionStatus?.metadata?.trackingNumber && <TrackingNumberCard trackingNumber={transactionStatus.metadata.trackingNumber} />}
        {transactionStatus?.metadata?.orderId && (
          <RateRequestSummaryCard title="Labels" showStatus={false} isClickable={false}>
            <PrintLabels orderId={transactionStatus.metadata.orderId} />
          </RateRequestSummaryCard>
        )}
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={[
          {
            action: handleCloseClick,
            label: t("drawer.transactionConfirmation.actions.nextButton.label"),
            variant: "contained",
            disabled: isFinishedButtonDisabled,
          },
        ]}
      />
    </SoeDrawerContainer>
  )
}

PaymentTransaction.propTypes = {
  setShowPaymentDrawer: PropTypes.func.isRequired,
}

export default PaymentTransaction
