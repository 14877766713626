import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { persistReducer } from "redux-persist"

import storageSession from "redux-persist/lib/storage/session"
import alertReducer from "./components/alert/slice"
import authenticatorReducer from "./components/authenticator/slice"
import drawerAlertReducer from "./components/drawer-alert/slice"
import modalReducer from "./components/modal/slice"
import quotationsReducer from "./components/quotations/slice"
import authApiSlice from "./services/auth-api/slice"
import restApiSlice from "./services/rest-api/slice"
import rtkQueryActionHandler from "./middleware/rtk-query-action-handler"
import paymentReducer from "./components/quotations/components/payment/slice"
import paymentMethodReducer from "./components/user-profile/slice"
import transactionReducer from "./components/quotations/components/payment/components/payment-transaction/slice"

const persistConfig = {
  key: "root",
  version: 1,
  storage: storageSession,
  blacklist: ["alert", authApiSlice.reducerPath, restApiSlice.reducerPath, "quotations", "stripe", "transactionReducer"],
}

const combinedReducers = combineReducers({
  alert: alertReducer,
  authApi: authApiSlice.reducer,
  authenticator: authenticatorReducer,
  drawer: drawerAlertReducer,
  modal: modalReducer,
  quotations: quotationsReducer,
  restApi: restApiSlice.reducer,
  transaction: transactionReducer,
  payment: paymentReducer,
  paymentMethod: paymentMethodReducer,
})

const rootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    return combinedReducers(undefined, action)
  }
  return combinedReducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(authApiSlice.middleware, restApiSlice.middleware, rtkQueryActionHandler),
})

export default store
