import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

import { styled } from "@mui/material/styles"

import ImpersonateService from "../../services/impersonate"

export const MuiWrapper = styled(Box, { name: "SoeAppBar", slot: "Root" })(() => ({
  flexGrow: 1,
}))

export const CustomButton = styled(Button, { label: "CustomButton", slot: "Root" })(({ theme }) => ({
  backgroundColor: "success",
  "& .MuiButton-startIcon": {
    "& .MuiSvgIcon-root": {
      fontSize: theme.utils.pxToRem(24),
      color: ImpersonateService.currentImpersonation() ? "red" : "primary",
    },
  },
}))
