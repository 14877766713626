const AppConfig = {
  app: {
    version: "2.0.0",
    environment: "QA",
    nodeEnv: "test",
    addressAutoCompleteTimeout: "350",
  },
  aws: {
    cognito: {
      userPoolId: "us-east-2_0rCNIJ2DJ",
      clientId: "6b89nr4n91sbbah0blil28dq5q",
      hostedUiUrl: "https://qasaveonexpress.auth.us-east-2.amazoncognito.com",
    },
  },
  externalLinks: {
    harmonizedSystemSearchUrl: "https://www.tariffinder.ca/en/getStarted",
  },
  restApi: {
    apiUrl: "https://api.qa.saveonexpress.ca",
  },
  websocket: {
    url: "wss://wss.qa.saveonexpress.ca",
    keepaliveDelay: "540000",
  },
  stripe: {
    publicKey: "pk_test_51KgDvOBCsctpTZ5ifuaGxoXkiCC7iTK2h8sKLYlsXtLnj468nzFVUP4jcEVFpPwQSeLsH5JErUq0fTWlT0ZIadDZ00L2GCXso3",
  },
  features: {
    showDiscountAmount: "false" === "true",
    showAddressValidation: "false" === "true",
    showOrderTypeColumn: "false" === "true",
  },
  logRocket: {
    id: "ylvrtg/soe",
  },
}

export default AppConfig
