import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import IconButton from "@mui/material/IconButton"
import PropTypes from "prop-types"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import React, { useEffect, useState } from "react"
import Typography from "@mui/material/Typography"

import { useDispatch, useSelector } from "react-redux"
import { FieldArray, FormikProvider, useFormik } from "formik"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import CardContentNoPaddingBottom from "../../../../../../soe-theme/src/components/cardContent-no-padding-bottom"
import PackageForm from "./components/package-form"
import PACKAGING_TYPE from "./constants"
import SoeDeleteOutlineIcon from "../../../../../../soe-theme/src/icons/SoeDeleteOutlineIcon"

import { SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../../../soe-theme/src/components"
import { UnitsOfMeasureEnum } from "../../../../../../services/units-conversion"
import { updateCommercialInvoice, updatePackages, useGetPackagingTypesQuery } from "../../../../slice"

function RateRequestPackages({ setShowRateRequestDrawer, showRateRequestDrawer, ...wizardProps }) {
  const { t } = useTranslation(["rateRequest"])
  const dispatch = useDispatch()
  const { data: packagingTypes } = useGetPackagingTypesQuery()

  const { packages, commercialInvoice } = useSelector((state) => state.quotations)

  const customTheme = useTheme()

  const [action, setAction] = useState("")

  const SPACING = 12

  const emptyPiece = {
    packagingType: packagingTypes?.find((aPackagingType) => aPackagingType.isDefault),
    quantity: 1,
    length: undefined,
    width: undefined,
    height: undefined,
    weight: undefined,
    itemCount: undefined,
  }

  const formik = useFormik({
    initialValues: {
      unitOfMeasure: packages?.unitOfMeasure || UnitsOfMeasureEnum.IMPERIAL,
      pieces: packages?.pieces?.length > 0 ? packages.pieces : [emptyPiece],
    },
    enableReinitialize: true,
    validate: (formValues) => {
      const errors = {
        pieces: [],
      }
      formValues.pieces.forEach((piece) => {
        const error = {}
        if (!piece.packagingType) {
          error.packagingType = true
        }
        if (!piece.quantity || piece.quantity < 0) {
          error.quantity = true
        }
        if (!piece.length || piece.length < 0) {
          error.length = true
        }
        if (!piece.width || piece.width < 0) {
          error.width = true
        }
        if (!piece.height || piece.height < 0) {
          error.height = true
        }
        if (!piece.packagingType?.isEnvelope && (!piece.weight || piece.weight < 0)) {
          error.weight = true
        }
        if (piece.packagingType?.categoryName === PACKAGING_TYPE.FREIGHT && (!piece.itemCount || piece.itemCount < 0)) {
          error.itemCount = true
        }
        errors.pieces.push(error)
      })
      let isValid = true

      errors.pieces.forEach((err) => {
        if (Object.keys(err).length > 0) {
          isValid = false
        }
      })
      return isValid ? undefined : errors
    },
    validateOnChange: false,
    onSubmit: async (formValues) => {
      const pieces = formValues.pieces.map((piece) => {
        return {
          packagingType: piece.packagingType,
          quantity: parseInt(piece.quantity, 10),
          length: parseFloat(piece.length),
          width: parseFloat(piece.width),
          height: parseFloat(piece.height),
          weight: piece.packagingType?.isEnvelope ? 1 : parseFloat(piece.weight),
          itemCount: piece.packagingType?.categoryName === PACKAGING_TYPE.FREIGHT ? parseFloat(piece.itemCount) : undefined,
        }
      })
      dispatch(updatePackages({ unitOfMeasure: formValues.unitOfMeasure, pieces }))
      if (commercialInvoice) dispatch(updateCommercialInvoice({ ...commercialInvoice, unitOfMeasure: formValues.unitOfMeasure }))
      if (action === "continue") wizardProps.nextStep()
      if (action === "saveAndClose") setShowRateRequestDrawer(false)
    },
  })

  useEffect(() => {
    if (!showRateRequestDrawer) formik.resetForm()
  }, [showRateRequestDrawer])

  const { values } = formik

  const handleSaveAndCloseClick = () => {
    formik.handleSubmit()
    setAction("saveAndClose")
  }

  const handleContinueClick = () => {
    formik.handleSubmit()
    setAction("continue")
  }

  return (
    <SoeDrawerContainer>
      <SoeDrawerHeader title={t("drawer.packages.title.label", { ns: "rateRequest" })} setShowDrawer={setShowRateRequestDrawer} />
      <SoeDrawerContent>
        <Card sx={(theme) => ({ mb: theme.utils.pxToThemeSpacing(SPACING) })}>
          <CardContentNoPaddingBottom
            sx={{
              "&:last-child": {
                pl: 1,
                pt: 0,
                pr: 0,
              },
            }}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="bodyMedium600">{t("drawer.packages.fields.unitOfMeasure.label")}</Typography>
              <FormControl>
                <RadioGroup row aria-labelledby="radio-unit-of-measure-group-label" name="unitOfMeasure" value={formik.values.unitOfMeasure} onChange={formik.handleChange}>
                  <FormControlLabel
                    value={UnitsOfMeasureEnum.IMPERIAL}
                    control={
                      <Radio
                        sx={{
                          color: customTheme.palette.contentActionDefault.main,
                          "&.Mui-checked": {
                            color: customTheme.palette.contentActionDefault.main,
                          },
                        }}
                      />
                    }
                    label={t("drawer.packages.unitOfMeasure.imperial", { ns: "rateRequest" })}
                  />
                  <FormControlLabel
                    value={UnitsOfMeasureEnum.METRIC}
                    control={
                      <Radio
                        sx={{
                          color: customTheme.palette.contentAlertPositiveDarker.main,
                          "&.Mui-checked": {
                            color: customTheme.palette.contentAlertPositiveDarker.main,
                          },
                        }}
                      />
                    }
                    label={t("drawer.packages.unitOfMeasure.metric", { ns: "rateRequest" })}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </CardContentNoPaddingBottom>
        </Card>
        <FormikProvider value={formik}>
          <form noValidate>
            <FieldArray
              name="pieces"
              render={(arrayHelpers) => (
                <Box component="div">
                  {values.pieces?.length > 0 &&
                    values.pieces.map((piece, index) => (
                      <Box component="div" key={index.toString().concat(piece.name)}>
                        <Card sx={(theme) => ({ mb: theme.utils.pxToThemeSpacing(SPACING) })}>
                          <CardContentNoPaddingBottom sx={(theme) => ({ px: theme.spacing(1), pt: theme.spacing(0) })}>
                            <Box
                              component="div"
                              mb={customTheme.utils.pxToThemeSpacing(SPACING)}
                              sx={(theme) => ({
                                height: theme.utils.pxToRem(40),
                                borderRadius: theme.utils.pxToRem(4),
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              })}
                            >
                              <Typography variant="bodyMedium600" sx={{ fontWeight: "bold" }}>
                                {`${t("drawer.packages.packageIndex.label", { ns: "rateRequest" })} - ${index + 1}`}
                              </Typography>
                              <IconButton color="primary" onClick={() => arrayHelpers.remove(index)} sx={(theme) => ({ pr: theme.spacing(0) })}>
                                <SoeDeleteOutlineIcon />
                              </IconButton>
                            </Box>
                            <PackageForm formik={formik} packagingTypes={packagingTypes} index={index} />
                          </CardContentNoPaddingBottom>
                        </Card>
                      </Box>
                    ))}
                  <Button variant="outlined" size="medium" onClick={() => arrayHelpers.push(emptyPiece)} sx={(theme) => ({ mb: theme.spacing(4), textTransform: "none" })}>
                    {t("drawer.packages.addAnotherPackage.label", { ns: "rateRequest" })}
                  </Button>
                </Box>
              )}
            />
          </form>
        </FormikProvider>
      </SoeDrawerContent>
      <SoeDrawerActions
        buttons={
          wizardProps.currentStep === wizardProps.totalSteps
            ? [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.packages.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
              ]
            : [
                {
                  action: handleSaveAndCloseClick,
                  label: t("drawer.packages.actions.prevButton.label", { ns: "rateRequest" }),
                  variant: "outlined",
                },
                {
                  action: handleContinueClick,
                  label: t("drawer.packages.actions.nextButton.label", { ns: "rateRequest" }),
                  variant: "contained",
                },
              ]
        }
      />
    </SoeDrawerContainer>
  )
}

RateRequestPackages.propTypes = {
  setShowRateRequestDrawer: PropTypes.func.isRequired,
  showRateRequestDrawer: PropTypes.bool.isRequired,
}

RateRequestPackages.defaultProps = {}

export default RateRequestPackages
