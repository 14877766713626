import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"

import Checkbox from "./components/checkbox"
import Input from "./components/input"
import Select from "./components/select"

import { useGetAdditionalFieldsQuery } from "../../slice"

const componentsEnum = {
  Checkbox,
  Input,
  Select,
}

function PickupAddtionalFields({ formik, carrier }) {
  const [components, setComponents] = useState(null)

  const { data: additionalFields, isFetching } = useGetAdditionalFieldsQuery(carrier.code)

  useEffect(() => {
    if (additionalFields) {
      const newComponents = additionalFields.map((additionalField, index) => {
        const ComponentName = componentsEnum[additionalField.form?.element]
        return <ComponentName key={"".concat(index)} formik={formik} fieldFormat={additionalField} />
      })
      setComponents(newComponents)
    }
  }, [additionalFields, formik])

  return <Stack width={1}>{isFetching ? <Skeleton variant="rectangular" height={50} width="100%" /> : components}</Stack>
}

PickupAddtionalFields.propTypes = {
  formik: PropTypes.instanceOf(Object).isRequired,
  carrier: PropTypes.instanceOf(Object).isRequired,
}

export default PickupAddtionalFields
