// REACT
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"

// Theme
import { useTheme } from "@mui/material/styles"

// Others
import { Typography } from "@mui/material"
import UserContext from "../../../../../../services/user/context"

function PaymentMethodDrawer({ onDeleteEvent, onUpdateEvent, setShowDrawer, paymentMethod, deletePaymentMethod, updatePaymentMethod }) {
  const { t } = useTranslation("userProfile")
  const customTheme = useTheme()

  const { currentUser } = useContext(UserContext)

  const titleCase = (str) => {
    if (!str) return str
    const splitStr = str.toLowerCase().split(" ")
    for (let i = 0; i < splitStr.length; i += 1) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(" ")
  }

  const formatExpiryDate = (month, year) => {
    const monthString = month.toString().padStart(2, "0")
    const yearString = year.toString().slice(2)
    return `${monthString}/${yearString}`
  }

  useEffect(() => {
    let payload = []
    if (onUpdateEvent !== undefined) {
      payload = currentUser.paymentMethods.map((currentPaymentMethod) => {
        if (currentPaymentMethod.status === "default") {
          return {
            ...currentPaymentMethod,
            status: "active",
          }
        }
        if (currentPaymentMethod.id === paymentMethod.id) {
          return {
            ...currentPaymentMethod,
            status: "default",
          }
        }
        return currentPaymentMethod
      })
      updatePaymentMethod({ ...currentUser, paymentMethods: payload }).then(() => {
        setShowDrawer(false)
      })
    }
  }, [onUpdateEvent])

  useEffect(() => {
    let payload = []
    if (onDeleteEvent !== undefined) {
      payload = currentUser.paymentMethods.map((currentPaymentMethod) => {
        if (currentPaymentMethod.id === paymentMethod.id) {
          return {
            ...currentPaymentMethod,
            status: "deleted",
          }
        }
        return currentPaymentMethod
      })
      deletePaymentMethod({ ...currentUser, paymentMethods: payload }).then(() => {
        setShowDrawer(false)
      })
    }
  }, [onDeleteEvent])

  return (
    <>
      <Box component="div">
        <Box component="div" my={customTheme.utils.pxToRem(10)}>
          <Typography>{t("userProfile.paymentMethodDrawer.fields.type.label")}</Typography>
          <Typography>{titleCase(paymentMethod?.card?.brand)}</Typography>
        </Box>
        <Box component="div" my={customTheme.utils.pxToRem(10)}>
          <Typography>{t("userProfile.paymentMethodDrawer.fields.number.label")}</Typography>
          <Typography>{`**** ${paymentMethod.card?.last4}`}</Typography>
        </Box>
        <Box component="div" my={customTheme.utils.pxToRem(10)}>
          <Typography>{t("userProfile.paymentMethodDrawer.fields.expiry.label")}</Typography>
          <Typography>{formatExpiryDate(paymentMethod?.card?.exp_month, paymentMethod?.card?.exp_year)}</Typography>
        </Box>
      </Box>
      {paymentMethod?.status === "default" && (
        <Box component="div">
          <Typography>{t("userProfile.paymentMethodDrawer.cannotDelete.label")}</Typography>
        </Box>
      )}
    </>
  )
}

PaymentMethodDrawer.defaultProps = {
  onDeleteEvent: undefined,
  onUpdateEvent: undefined,
  paymentMethod: undefined,
  deletePaymentMethod: undefined,
  updatePaymentMethod: undefined,
}

PaymentMethodDrawer.propTypes = {
  onDeleteEvent: PropTypes.instanceOf(Object),
  onUpdateEvent: PropTypes.instanceOf(Object),
  setShowDrawer: PropTypes.func.isRequired,
  paymentMethod: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    card: PropTypes.shape({
      brand: PropTypes.string,
      last4: PropTypes.string,
      exp_month: PropTypes.number,
      exp_year: PropTypes.number,
    }),
  }),
  deletePaymentMethod: PropTypes.func,
  updatePaymentMethod: PropTypes.func,
}

export default PaymentMethodDrawer
