import { totalPackages, totalWeight } from "../../utils/packages"
import restApiSlice from "../rest-api/slice"
import convertTZ from "../../utils/convert-timezone"

const shipmentsRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getShipments: builder.query({
      query: ({ from, to }) => ({ url: "/orders/me", params: { from, to }, method: "GET" }),
      providesTags: ["Shipments"],
      transformResponse: (response) => {
        response.data.map((order) => {
          order.totalPackages = totalPackages(order?.quotation?.request?.packages?.pieces)
          order.totalWeight = totalWeight(order?.quotation?.request?.packages?.pieces)
          return order
        })
        return response.data
      },
    }),
    getShipment: builder.query({
      query: (orderId) => ({ url: `/orders/${orderId}`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    getPendingShipments: builder.query({
      query: () => ({ url: "/orders/me/pending", method: "GET" }),
      providesTags: ["PendingShipments"],
      transformResponse: (response) => {
        response.data.forEach((groupedShipment) => {
          groupedShipment.orders.forEach((groupedShipmentsByAddress) => {
            groupedShipmentsByAddress.orders.forEach((groupedShipmentsByCarrier) => {
              groupedShipmentsByCarrier.orders.forEach((order) => {
                order.totalPackages = totalPackages(order?.quotation?.request?.packages?.pieces)
                order.totalWeight = totalWeight(order?.quotation?.request?.packages?.pieces)
              })
            })
          })
        })
        return response.data
      },
    }),
    getInTransitShipments: builder.query({
      query: ({ from, to }) => ({ url: "/orders/me/intransit", params: { from, to }, method: "GET" }),
      providesTags: ["inTransitShipments"],
      transformResponse: (response) => {
        response.data.map((order) => {
          order.totalPackages = totalPackages(order?.quotation?.request?.packages?.pieces)
          order.totalWeight = totalWeight(order?.quotation?.request?.packages?.pieces)
          order.deliveryDate = convertTZ(`${order.quotation?.response?.commitment?.commitDate} ${order.quotation?.response?.commitment?.commitTime}`, order.quotation?.response?.commitment?.timezone)
          return order
        })
        return response.data
      },
    }),
    getCompletedShipments: builder.query({
      query: ({ from, to }) => ({ url: "/orders/me/complete", params: { from, to }, method: "GET" }),
      providesTags: ["completedShipments"],
      transformResponse: (response) => {
        response.data.map((order) => {
          order.totalPackages = totalPackages(order?.quotation?.request?.packages?.pieces)
          order.totalWeight = totalWeight(order?.quotation?.request?.packages?.pieces)
          return order
        })
        return response.data
      },
    }),
    deleteOrder: builder.mutation({
      query: (orderId) => ({ url: `/v2/carriers/generic/void/${orderId}`, method: "DELETE" }),
      transformResponse: (response) => response.data,
    }),
    getPrintLabels: builder.query({
      query: ({ orderId }) => ({ url: `/v2/carriers/generic/label/${orderId}`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
    getTrackingInfo: builder.query({
      query: (orderId) => ({ url: `/orders/${orderId}/track`, method: "GET" }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useDeleteOrderMutation, useGetShipmentQuery, useGetShipmentsQuery, useGetPendingShipmentsQuery, useGetCompletedShipmentsQuery, useGetInTransitShipmentsQuery, useGetPrintLabelsQuery, useGetTrackingInfoQuery } = shipmentsRestApiSlice

export default shipmentsRestApiSlice.reducer
