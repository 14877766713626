import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AuthenticationDetails, CognitoAccessToken, CognitoIdToken, CognitoRefreshToken, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js"
import LogRocket from "logrocket"
import authApiSlice from "../../services/auth-api/slice"
import UserPool from "../../services/user-pool"
import AppConfig from "../../utils/app-config"

export const signIn = createAsyncThunk("auth/signIn", async (formValues) => {
  return new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: formValues.email,
      Pool: UserPool,
      Storage: window.sessionStorage,
    })
    user.authenticateUser(
      new AuthenticationDetails({
        Username: formValues.email,
        Password: formValues.password,
      }),
      {
        onSuccess: (data) => {
          resolve(data)
        },
        onFailure: (error) => {
          reject(error)
        },
        newPasswordRequired: (userAttributes) => {
          delete userAttributes.email_verified
          delete userAttributes.phone_number_verified
          resolve({ setNewPassword: true, userAttributes, user })
        },
      }
    )
  })
})

export const cognitoSignIn = createAsyncThunk("auth/cognitoSignIn", (tokenData) => {
  return new Promise((resolve, reject) => {
    try {
      const AccessToken = new CognitoAccessToken({ AccessToken: tokenData.access_token })
      const IdToken = new CognitoIdToken({ IdToken: tokenData.id_token })
      const RefreshToken = new CognitoRefreshToken({ RefreshToken: tokenData.refresh_token })
      const { username } = AccessToken.decodePayload()
      const user = new CognitoUser({
        Username: username,
        Pool: UserPool,
        Storage: window.sessionStorage,
      })
      user.setSignInUserSession(
        new CognitoUserSession({
          AccessToken,
          IdToken,
          RefreshToken,
        })
      )
      resolve(user)
    } catch (error) {
      reject(error)
    }
  })
})

export const signUp = createAsyncThunk("auth/signUp", async (formValues) => {
  return new Promise((resolve, reject) => {
    UserPool.signUp(formValues.email?.toLowerCase().trim(), formValues.password, [], null, (err) => {
      if (err) {
        reject(err)
      } else {
        resolve(formValues.email.toLowerCase().trim())
      }
    })
  })
})

export const confirmSignUp = createAsyncThunk("auth/confirmSignUp", async (formValues) => {
  return new Promise((resolve, reject) => {
    new CognitoUser({
      Username: formValues.email,
      Pool: UserPool,
      Storage: window.sessionStorage,
    }).confirmRegistration(formValues.verificationCode, true, (err, result) => {
      if (err) {
        reject(err)
      } else {
        resolve(result)
      }
    })
  })
})

export const signOut = createAsyncThunk("auth/signOut", async () => {
  const user = UserPool.getCurrentUser()
  if (user) user.signOut()
})

export const forgotPassword = createAsyncThunk("auth/forgotPassword", async (formValues) => {
  return new Promise((resolve, reject) => {
    new CognitoUser({
      Username: formValues.email,
      Pool: UserPool,
      Storage: window.sessionStorage,
    }).forgotPassword({
      onFailure(err) {
        reject(err)
      },
      onSuccess() {
        resolve(formValues.email)
      },
      inputVerificationCode() {
        resolve(formValues.email)
      },
    })
  })
})

export const forgotPasswordSubmit = createAsyncThunk("auth/forgotPasswordSubmit", async (formValues) => {
  return new Promise((resolve, reject) => {
    new CognitoUser({
      Username: formValues.email,
      Pool: UserPool,
      Storage: window.sessionStorage,
    }).confirmPassword(formValues.verificationCode, formValues.newPassword, {
      onSuccess: (data) => {
        resolve(data)
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
})

export const changePassword = createAsyncThunk("auth/changePassword", async (formValues) => {
  return new Promise((resolve, reject) => {
    formValues.cognitoUser.completeNewPasswordChallenge(formValues.newPassword, null, {
      onSuccess: (result) => {
        resolve(result)
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
})

export const currentAuthenticatedUser = createAsyncThunk("auth/currentAuthenticatedUser", async () => {
  return new Promise((resolve, reject) => {
    const user = UserPool.getCurrentUser()
    if (user) {
      user.getSession(async (err) => {
        if (err) {
          reject(err)
        } else {
          user.getUserAttributes((error, attributes) => {
            if (error) {
              reject(error)
            } else {
              const email = attributes?.find((attribute) => attribute.Name === "email")?.Value
              if (email && AppConfig.logRocket?.id) {
                LogRocket.identify(email, {
                  Email: email,
                  AppVersion: AppConfig.app.version,
                })
              }
              const results = {}
              attributes.forEach((attribute) => {
                const { Name, Value } = attribute
                results[Name] = Value
              })
              resolve(results)
            }
          })
        }
      })
    } else {
      reject(new Error("User is not authenticated"))
    }
  })
})

const initialState = {
  alert: {
    showAlert: false,
    severity: undefined,
    message: undefined,
  },
  formState: "signIn",
  showAuthenticator: false,
  email: undefined,
}

export const authenticatorSlice = createSlice({
  name: "authenticator",
  initialState,
  reducers: {
    showForgotPassword: (state) => {
      state.formState = "forgotPassword"
    },
    showSignIn: (state) => {
      state.formState = "signIn"
    },
    showSignUp: (state) => {
      state.formState = "signUp"
    },
    setShowAuthenticator: (state, action) => {
      state.showAuthenticator = action.payload
    },
    setLastLocation: (state, action) => {
      state.lastLocation = action.payload
    },
    clearLastLocation: (state) => {
      state.lastLocation = undefined
    },
  },
  extraReducers: {
    [currentAuthenticatedUser.fulfilled]: (state, action) => {
      if (action.payload !== undefined) {
        state.formState = "signedIn"
      } else {
        state.formState = "signIn"
      }
    },
    [signIn.fulfilled]: (state, action) => {
      if (action?.payload?.setNewPassword) {
        state.cognitoUser = action.payload.user
        state.userAttributes = action.payload.userAttributes
        state.formState = "changePassword"
      } else {
        state.formState = "signedIn"
      }
    },
    [signIn.rejected]: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "error"
      state.alert.message = action.error.message
    },
    [cognitoSignIn.fulfilled]: (state) => {
      state.formState = "signedIn"
    },
    [cognitoSignIn.rejected]: (state) => {
      state.formState = "signIn"
    },
    [signUp.fulfilled]: (state, action) => {
      state.formState = "confirmSignUp"
      state.email = action.payload
    },
    [signUp.rejected]: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "error"
      state.alert.message = action.error.message
    },
    [confirmSignUp.fulfilled]: (state) => {
      state.email = undefined
    },
    [confirmSignUp.rejected]: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "error"
      state.alert.message = action.error.message
    },
    [signOut.fulfilled]: (state) => {
      state.formState = "signIn"
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.formState = "forgotPasswordSubmit"
      state.email = action.payload
    },
    [forgotPassword.rejected]: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "error"
      state.alert.message = action.error.message
    },
    [forgotPasswordSubmit.fulfilled]: (state) => {
      state.formState = "signIn"
      state.email = undefined
    },
    [forgotPasswordSubmit.rejected]: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "error"
      state.alert.message = action.error.message
    },
    [changePassword.fulfilled]: (state) => {
      state.cognitoUser = undefined
      state.userAttributes = undefined
      state.formState = "signedIn"
    },
    [changePassword.rejected]: (state, action) => {
      state.alert.showAlert = true
      state.alert.severity = "error"
      state.alert.message = action.error.message
    },
  },
})

export const getJwtTokens = async () => {
  return new Promise((resolve, reject) => {
    let user
    try {
      user = UserPool.getCurrentUser()
    } catch (err) {
      reject(new Error("Error getting current user", err))
    }
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          reject(new Error("Error getting session", err))
        }
        resolve({
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken(),
        })
      })
    } else {
      reject(new Error("User is not authenticated"))
    }
  })
}

export const authenticatorRestApiSlice = authApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getToken: builder.query({
      query: (code) => ({
        url: "/oauth2/token/",
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
        params: {
          grant_type: "authorization_code",
          code,
          client_id: `${AppConfig.aws.cognito.clientId}`,
          redirect_uri: `${window.location.origin}/googleauth`,
        },
      }),
      extraOptions: {
        target: "authenticator",
      },
    }),
  }),
})

export const { useGetTokenQuery } = authenticatorRestApiSlice

export const { clearLastLocation, setLastLocation, setShowAuthenticator, showForgotPassword, showSignIn, showSignUp } = authenticatorSlice.actions

export default authenticatorSlice.reducer
