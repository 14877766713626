import camelCase from "lodash/camelCase"
import { createSlice } from "@reduxjs/toolkit"
import restApiSlice from "../../../../../../services/rest-api/slice"

const initialState = {
  transactionStatus: undefined,
}

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    clearTransaction: () => initialState,
    setTransactionStatus: (state, action) => {
      const transactionStatus = action.payload
      if (transactionStatus && transactionStatus.status) {
        transactionStatus.status = camelCase(transactionStatus.status)
      }
      state.transactionStatus = action.payload
    },
  },
  extraReducers: {
    RESET_QUOTATIONS: () => initialState,
  },
})

export const { clearTransaction, setTransactionStatus } = transactionSlice.actions

/**
 * We use restApiSlice (RTK-Query) to communicate with the backend
 */
export const transactionRestApiSlice = restApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postOrderConfirm: builder.query({
      query: ({ payload, connectionId }) => ({ url: `/payments/confirm`, headers: { connectionId }, method: "POST", body: payload }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { usePostOrderConfirmQuery } = transactionRestApiSlice

export default transactionSlice.reducer
