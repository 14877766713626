// REACT
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Masonry from "@mui/lab/Masonry"
import Typography from "@mui/material/Typography"

// Custom theme
import { useTheme } from "@mui/material"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"

// Others
import MonetaryInfoBillingContactCard from "./components/monetary-info-billing-contact-card"
import MonetaryInfoBillingPrefCard from "./components/monetary-info-billing-pref-card"
import MonetaryInfoBillingPrefDrawer from "./components/monetary-info-billing-pref-drawer"
import MonetaryInfoBillingContactDrawer from "./components/monetary-info-billing-contact-drawer"

function UserProfileMonetaryInfo() {
  const { t } = useTranslation("userProfile")
  const customTheme = useTheme()

  const [showMonetaryInfoBillingContactDrawer, setShowMonetaryInfoBillingContactDrawer] = useState(false)
  const [showMonetaryInfoBillingPrefDrawer, setShowMonetaryInfoBillingPrefDrawer] = useState(false)

  const [onSubmitEvent, setSubmitEvent] = useState()
  const handleMonetaryInfoBillingContactCardClick = () => {
    setSubmitEvent()
    setShowMonetaryInfoBillingContactDrawer(true)
  }

  const handleMonetaryInfoBillingPrefCardClick = () => {
    setSubmitEvent()
    setShowMonetaryInfoBillingPrefDrawer(true)
  }

  return (
    <Box component="div">
      <Box display="flex" pt={customTheme.utils.pxToThemeSpacing(20)} pr={customTheme.utils.pxToThemeSpacing(10)} pb={customTheme.utils.pxToThemeSpacing(20)} pl={customTheme.utils.pxToThemeSpacing(10)}>
        <Box component="h5">
          <Typography sx={(theme) => ({ fontSize: customTheme.utils.pxToRem(18), fontWeight: theme.typography.fontWeightBold })}>{t("userProfile.monetaryInformation.title.label")}</Typography>
        </Box>
      </Box>
      <Box pl={customTheme.utils.pxToThemeSpacing(6)} sx={{ display: "flex" }}>
        <Masonry columns={4} sx={{ alignContent: "flex-start" }}>
          <MonetaryInfoBillingContactCard handleMonetaryInfoBillingContactCardClick={() => handleMonetaryInfoBillingContactCardClick()} setShowMonetaryInfoBillingContactDrawer={setShowMonetaryInfoBillingContactDrawer} />
          <MonetaryInfoBillingPrefCard handleMonetaryInfoBillingPrefCardClick={() => handleMonetaryInfoBillingPrefCardClick()} />
        </Masonry>
      </Box>
      <SoeDrawer showDrawer={showMonetaryInfoBillingContactDrawer} setShowDrawer={setShowMonetaryInfoBillingContactDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.monetaryBillingContactDrawer.title")}
            setShowDrawer={() => {
              setShowMonetaryInfoBillingContactDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <MonetaryInfoBillingContactDrawer onSubmitEvent={onSubmitEvent} setShowMonetaryInfoBillingContactDrawer={setShowMonetaryInfoBillingContactDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.monetaryBillingContactDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
      <SoeDrawer showDrawer={showMonetaryInfoBillingPrefDrawer} setShowDrawer={setShowMonetaryInfoBillingPrefDrawer} anchor="right" keepMounted={false}>
        <SoeDrawerContainer>
          <SoeDrawerHeader
            title={t("userProfile.monetaryBillingPrefInfoCard.title")}
            setShowDrawer={() => {
              setShowMonetaryInfoBillingPrefDrawer(false)
            }}
          />
          <SoeDrawerContent>
            <MonetaryInfoBillingPrefDrawer onSubmitEvent={onSubmitEvent} setShowMonetaryInfoBillingPrefDrawer={setShowMonetaryInfoBillingPrefDrawer} />
          </SoeDrawerContent>
          <SoeDrawerActions
            buttons={[
              {
                action: setSubmitEvent,
                label: t("userProfile.monetaryBillingPrefInfoDrawer.actions.save.label"),
                variant: "contained",
              },
            ]}
          />
        </SoeDrawerContainer>
      </SoeDrawer>
    </Box>
  )
}

export default UserProfileMonetaryInfo
