// REACT
import React, { useState } from "react"

import { useTranslation } from "react-i18next"

// MUI
import Box from "@mui/material/Box"
import Chip from "@mui/material/Chip"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import Typography from "@mui/material/Typography"

// Others
import AddressSummary from "../../../../../address-summary"
import AppConfig from "../../../../../../utils/app-config"
import CarrierServiceLogo from "../../../../../carrier-service-logo"
import Currency from "../../../../../currency"
import DateTimeService from "../../../../../../services/date-time"
import MoreMenu from "../../../../../more-menu"
import OrderProps from "../../../../../../models/order"
import PrintLabelsDrawer from "../../../shipment/components/print-labels-drawer"
import ShipmentDetailsDrawer from "../../../shipment-details-drawer"
import ShipmentsStatus from "../../../shipment/constants"
import TrackingDrawer from "../../../tracking-drawer"

import { IMPERIAL, METRIC } from "../../../../../../services/units-conversion"

function CompletedShipment({ completedShipment }) {
  const { t } = useTranslation("shipments")
  const [showShipmentDetailsDrawer, setShowShipmentDetailsDrawer] = useState(false)
  const [showPrintLabelsDrawer, setShowPrintLabelsDrawer] = useState(false)
  const [showTrackingDrawer, setShowTrackingDrawer] = useState(false)

  const handleShipmentClick = () => {
    setShowShipmentDetailsDrawer(true)
  }

  const handlePrintLabels = () => {
    setShowPrintLabelsDrawer(true)
  }

  const handleTrackingClick = () => {
    setShowTrackingDrawer(true)
  }

  const sideMenuOptionList = [
    {
      label: t("completedOrderDetails.optionsList.details.label"),
      handleClick: handleShipmentClick,
    },
    {
      label: t("completedOrderDetails.optionsList.track.label"),
      handleClick: handleTrackingClick,
    },
    {
      label: t("aShipmentDetails.optionsList.printLabels.label"),
      handleClick: handlePrintLabels,
    },
  ]

  const handleClose = () => {
    setShowShipmentDetailsDrawer(false)
  }

  return (
    <>
      <TableRow hover onClick={handleShipmentClick} component="tr" sx={{ backgroundColor: "white" }}>
        <TableCell padding="none" align="center">
          <CarrierServiceLogo code={completedShipment.quotation?.response?.carrier?.code} logo={completedShipment.quotation?.response?.carrier?.logo} align="center" serviceName={completedShipment.quotation?.response?.service?.shortName} />
        </TableCell>
        <TableCell component="td" scope="row" align="center">
          {AppConfig.features.showOrderTypeColumn && <Chip label={t("aShipmentParts.parcel.label")} color="secondary" size="small" />}
        </TableCell>
        <TableCell component="td" scope="row" align="center">
          <Box>{completedShipment.number}</Box>
          <Box>{completedShipment.tracking?.masterId}</Box>
        </TableCell>
        <TableCell component="td" scope="row">
          <AddressSummary addressSummary={completedShipment.quotation?.request?.shipper} />
        </TableCell>
        <TableCell component="td" scope="row">
          <AddressSummary addressSummary={completedShipment.quotation?.request?.recipient} />
        </TableCell>
        <TableCell component="td" scope="row">
          {completedShipment.totalPackages}
        </TableCell>
        <TableCell component="td" scope="row">
          {completedShipment.totalWeight.toFixed(1)} {completedShipment?.quotation?.request?.packages?.unitOfMeasure === IMPERIAL && "lb"}
          {completedShipment?.quotation?.request?.packages?.unitOfMeasure === METRIC && "kg"}
        </TableCell>
        <TableCell component="td" scope="row">
          <Currency amount={completedShipment.quotation?.response?.rate?.totals?.discounted?.total?.amount} currency={completedShipment.quotation?.response?.rate?.totals?.discounted?.total?.currency} currencyPosition="right" showSymbol />
        </TableCell>
        <TableCell component="td" scope="row">
          <Box component="div">
            <Typography variant="bodySmall400">{DateTimeService.toLocaleDateString(completedShipment.modifiedDate)}</Typography>
          </Box>
          <Box component="div">
            <Typography variant="bodySmall400" gutterBottom>
              {DateTimeService.toLocaleTimeStringWithoutOptions(completedShipment.modifiedDate)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell component="td" scope="row">
          {completedShipment?.transitStatus === ShipmentsStatus.Pending && t("shipmentStatus.pending.label")}
          {completedShipment?.transitStatus === ShipmentsStatus.InTransit && t("shipmentStatus.inTransit.label")}
          {completedShipment?.transitStatus === ShipmentsStatus.Completed && t("shipmentStatus.completed.label")}
          {completedShipment?.transitStatus === ShipmentsStatus.Canceled && t("shipmentStatus.canceled.label")}
        </TableCell>
        <TableCell component="td" scope="row" />
        <TableCell component="td" scope="row" align="right">
          <MoreMenu optionsList={sideMenuOptionList} />
        </TableCell>
      </TableRow>
      {showPrintLabelsDrawer && <PrintLabelsDrawer selectedShipment={completedShipment} setShowPrintLabelsDrawer={setShowPrintLabelsDrawer} showPrintLabelsDrawer={showPrintLabelsDrawer} />}
      {showShipmentDetailsDrawer && (
        <ShipmentDetailsDrawer
          selectedOrderId={completedShipment.id}
          showShipmentDetailsDrawer={showShipmentDetailsDrawer}
          setShowShipmentDetailsDrawer={setShowShipmentDetailsDrawer}
          actions={[
            {
              action: handleClose,
              label: t("shipmentDetailsDrawer.actions.close"),
              variant: "contained",
            },
          ]}
        />
      )}
      {showTrackingDrawer && <TrackingDrawer selectedOrderId={completedShipment.id} showTrackingDrawer={showTrackingDrawer} setShowTrackingDrawer={setShowTrackingDrawer} />}
    </>
  )
}

CompletedShipment.propTypes = {
  completedShipment: OrderProps.isRequired,
}

CompletedShipment.defaultProps = {}

export default CompletedShipment
