import Box from "@mui/system/Box"
import PropTypes from "prop-types"
import React from "react"
import Typography from "@mui/material/Typography"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

import Currency from "../currency"
import RateRequestSummaryCard from "../rate-request-summary-card"

import { billDutyToList, businessRelationshipList, importExportList } from "../../utils/international-options"
import { isInternationalOptionsComplete } from "../quotations/utils"
import { InternationalOptionsProps } from "../../models/rate-request"

function InternationalOptionsCard({ internationalOptions, setShowRateRequestDrawer, disabled, isEditable, ...sw }) {
  const { t } = useTranslation("quotations")
  const theme = useTheme()

  const showRateRequestDrawer = () => {
    sw.stepWizard.goToNamedStep("international-options")
    setShowRateRequestDrawer(true)
  }

  const summaryStatusProps = {
    variant: isInternationalOptionsComplete(internationalOptions) ? "success" : "warning",
  }

  return (
    !disabled && (
      <RateRequestSummaryCard title={t("internationalOptionsTitle", { ns: "quotations" })} handleClick={showRateRequestDrawer} summaryStatusProps={summaryStatusProps} disabled={disabled} showStatus={isEditable} isClickable={isEditable}>
        {internationalOptions && (
          <Box mt={1} sx={{ display: "flex", flexDirection: "column" }}>
            {internationalOptions.documentsOnly && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {t("internationalOptionsCard.documentsOnly", { ns: "quotations" })}
              </Typography>
            )}
            {internationalOptions.declaredValue && internationalOptions.declaredValue.amount && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {t("internationalOptionsCard.declaredValue", { ns: "quotations" })}
                <Currency amount={internationalOptions.declaredValue.amount} currency={internationalOptions.declaredValue.currency} currencyPosition="right" color={theme.palette.contentNeutralSecondary.main} showSymbol />
              </Typography>
            )}
            {internationalOptions.billDutyTo && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {t("internationalOptionsCard.billDutyTo.label", { ns: "quotations" })}
                {t(`internationalOptionsCard.billDutyTo.options.${billDutyToList.find((billDutyTo) => billDutyTo.value === internationalOptions.billDutyTo).label}`, { ns: "quotations" })}
              </Typography>
            )}
            {internationalOptions.dutyAccountNumber && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {t("internationalOptionsCard.dutyAccountNumber.label", { ns: "quotations" })}
                {internationalOptions.dutyAccountNumber}
              </Typography>
            )}
            {internationalOptions.recipientTaxId && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {t("internationalOptionsCard.recipientTaxId", { ns: "quotations" })}
                {internationalOptions.recipientTaxId}
              </Typography>
            )}
            {internationalOptions.senderIsProducer && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {t("internationalOptionsCard.senderIsProducer", { ns: "quotations" })}
              </Typography>
            )}
            {internationalOptions.businessRelationship && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {t("internationalOptionsCard.businessRelationship.label", { ns: "quotations" })}
                {t(`internationalOptionsCard.businessRelationship.options.${businessRelationshipList.find((businessRelationship) => businessRelationship.value === internationalOptions.businessRelationship).label}`, { ns: "quotations" })}
              </Typography>
            )}
            {internationalOptions.exportType && (
              <Typography sx={{ color: theme.palette.contentNeutralSecondary.main, lineHeight: theme.utils.pxToLineHeight(16, 22) }} variant="bodySmall400" gutterBottom>
                {t("internationalOptionsCard.exportType.label", { ns: "quotations" })}
                {t(`internationalOptionsCard.exportType.options.${importExportList.find((importExport) => importExport.value === internationalOptions.exportType).label}`, { ns: "quotations" })}
              </Typography>
            )}
          </Box>
        )}
      </RateRequestSummaryCard>
    )
  )
}

InternationalOptionsCard.propTypes = {
  internationalOptions: InternationalOptionsProps,
  setShowRateRequestDrawer: PropTypes.func,
  disabled: PropTypes.bool,
  isEditable: PropTypes.bool,
}

InternationalOptionsCard.defaultProps = {
  internationalOptions: undefined,
  setShowRateRequestDrawer: () => {},
  disabled: false,
  isEditable: true,
}

export default InternationalOptionsCard
