import { useFormik } from "formik"
import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import UserContext from "../../../../services/user/context"
import FullAddressForm from "../../../full-address-form"
import { SoeDrawer, SoeDrawerActions, SoeDrawerContainer, SoeDrawerContent, SoeDrawerHeader } from "../../../../soe-theme/src/components"
import { useAddAddressBookItemMutation, useEditAddressBookItemMutation } from "../../slice"
import { getAddressFormat, isRequiredField } from "../../../../services/address-form"
import AddressProps from "../../../../models/address"
import { useGetAddressFormatsQuery, useGetCountriesQuery } from "../../../../services/address-form/slice"

function AddressBookDrawer({ selectedAddressBookItem, showAddressBookDrawer, setShowAddressBookDrawer }) {
  const { currentUser } = useContext(UserContext)
  const { t } = useTranslation("addressBook")

  const { data: countries } = useGetCountriesQuery()
  const { data: addressFormats } = useGetAddressFormatsQuery()

  const [addAddressBookItem] = useAddAddressBookItemMutation()
  const [editAddressBookItem] = useEditAddressBookItemMutation()

  const formik = useFormik({
    initialValues: {
      companyName: (selectedAddressBookItem && selectedAddressBookItem.companyName) || "",
      personName: (selectedAddressBookItem && selectedAddressBookItem.personName) || "",
      addressLine1: (selectedAddressBookItem && selectedAddressBookItem.addressLine1) || "",
      addressLine2: (selectedAddressBookItem && selectedAddressBookItem.addressLine2) || "",
      city: (selectedAddressBookItem && selectedAddressBookItem.city) || "",
      provinceName: (selectedAddressBookItem && selectedAddressBookItem.provinceName) || "",
      provinceCode: (selectedAddressBookItem && selectedAddressBookItem.provinceCode) || "",
      countryCode: (selectedAddressBookItem && selectedAddressBookItem.countryCode) || currentUser?.clientAddress?.countryCode || "CA",
      postalCode: (selectedAddressBookItem && selectedAddressBookItem.postalCode) || "",
      phoneNumber: (selectedAddressBookItem && selectedAddressBookItem.phoneNumber) || "",
      emailAddress: (selectedAddressBookItem && selectedAddressBookItem.emailAddress) || "",
      residential: (selectedAddressBookItem && selectedAddressBookItem.residential) || false,
    },
    validate: (formValues) => {
      const errors = {}

      const addressFormat = getAddressFormat({ addressFormats, countries, countryCode: formValues.countryCode })

      addressFormat?.forEach((fieldFormat) => {
        if (isRequiredField({ addressFormat, fieldName: fieldFormat.name }) && !formValues[fieldFormat.name]) {
          errors[fieldFormat.name] = t(`fields.${fieldFormat.name}.error.required`)
        }
      })

      return errors
    },
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (formValues) => {
      if (!selectedAddressBookItem) {
        await addAddressBookItem(formValues)
      } else {
        await editAddressBookItem({ id: selectedAddressBookItem.id, payload: formValues })
      }
      formik.resetForm()
      setShowAddressBookDrawer(false)
    },
  })

  useEffect(() => {
    formik.setErrors({})
    formik.setTouched({})
  }, [formik.values.countryCode])

  const handleClose = () => {
    formik.resetForm()
    setShowAddressBookDrawer(false)
  }

  const handleAddClick = () => {
    formik.handleSubmit()
  }

  return (
    <SoeDrawer showDrawer={showAddressBookDrawer} setShowDrawer={setShowAddressBookDrawer} anchor="right" keepMounted={false}>
      <SoeDrawerContainer>
        <SoeDrawerHeader
          title={t("drawer.title.label", { ns: "addressBook" })}
          setShowDrawer={() => {
            setShowAddressBookDrawer(false)
          }}
        />
        <SoeDrawerContent>
          <FullAddressForm formik={formik} displaySaveAddressCheckbox={false} searchAddressBook={false} />
        </SoeDrawerContent>
        <SoeDrawerActions
          buttons={[
            {
              action: handleClose,
              label: t("drawer.addAddressBookItem.actions.cancel.label", { ns: "addressBook" }),
              variant: "outlined",
            },
            {
              action: handleAddClick,
              label: selectedAddressBookItem ? t("drawer.addAddressBookItem.actions.save.label", { ns: "addressBook" }) : t("drawer.addAddressBookItem.actions.add.label", { ns: "addressBook" }),
              variant: "contained",
            },
          ]}
        />
      </SoeDrawerContainer>
    </SoeDrawer>
  )
}

AddressBookDrawer.propTypes = {
  selectedAddressBookItem: AddressProps,
  showAddressBookDrawer: PropTypes.bool.isRequired,
  setShowAddressBookDrawer: PropTypes.func.isRequired,
}

AddressBookDrawer.defaultProps = {
  selectedAddressBookItem: undefined,
}

export default AddressBookDrawer
