import PropTypes from "prop-types"
import React from "react"

import CarrierHeader from "../carrier-header"
import Shipment from "../../../shipment"
import getComparator from "../../../../../../utils/sorting"

function GroupedOrder({ groupedShipmentsByAddress, shippingDate, order, orderBy }) {
  return (
    <>
      {groupedShipmentsByAddress.orders.map((groupedShipmentsByCarrier, index) => {
        return (
          <React.Fragment key={"".concat(index)}>
            <CarrierHeader carrier={groupedShipmentsByCarrier.carrier} address={groupedShipmentsByAddress.address} pickup={groupedShipmentsByCarrier.pickup} shippingDate={shippingDate} ordersCount={groupedShipmentsByAddress.orders.length} />
            {groupedShipmentsByCarrier.orders
              .slice()
              .sort(getComparator(order, orderBy))
              .map((shipment) => {
                return <Shipment key={shipment.id} shipment={shipment} showServiceName />
              })}
          </React.Fragment>
        )
      })}
    </>
  )
}

GroupedOrder.propTypes = {
  groupedShipmentsByAddress: PropTypes.shape({
    address: PropTypes.shape({}),
    orders: PropTypes.arrayOf(
      PropTypes.shape({
        quotation: PropTypes.shape({
          response: PropTypes.shape({
            carrier: PropTypes.shape({}),
          }),
          request: PropTypes.shape({
            shipper: PropTypes.shape({}),
          }),
        }),
      })
    ),
    pickup: PropTypes.instanceOf(Object),
  }).isRequired,
  shippingDate: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
}

export default GroupedOrder
